// import starting from the src/ folder
@import "variables";
@import "Logs";
@import "WelcomePage";
@import "LoginPage";
@import "AboutPage";
@import "BorderLeftBlock";
@import "VideoContentPage";
@import "VideoBlockItem";
@import "MainNavBar";
@import "Expander";
@import "UsersPage";
@import "LogsPage";
@import "PublicPluginsPage";
@import "MainUserPage";
@import "PlaginsInstallPage";
@import "SearchPanel";
@import "BasicTopNavBar";
@import "CircleLoader";
@import "Dropdown";
@import "CloudConfigPage";
@import "StatsPage";
@import "AddPluginPage";
@import "TextInput";
@import "HotkeyInput";
@import "AddLibraryDllFilePage";
@import "ProgressBar";
@import "LocalPluginsPage";
@import "AgreementPage";
@import "CustomSlider";
@import "PluginManagerPage";
@import "InvalidTime";
@import "AccountPage";
@import "LangSelector";
@import "TestPage";
@import "ResetPasswordPage";
@import "Block";
@import "TrsansactionBlock";
@import "Circle";
@import "termOfUse";
@import "Footer";
@import "SubInfioBlock";
@import "SuccessPaymentPage";
@import "BuySubscribePage";
@import "Maintenance";
@import "PaymentListBlock";
@import "ConfirmEmailPage";
@import "NewYearBannerComponent";
@import "NewYearWelcomePageBanner";
@import "NewYearTopPanelComponent";
@import "SnowBackground";
@import "SignInCallbackPage";
@import "Forum";
@import "HtmlEditor";
@import "CrasherBanner";

//@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto&display=swap');
//
//@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

//@font-face {
//  font-family: 'RobotoCustom';
//  src: local('RobotoCustom'), url('/src/fonts/roboto-medium.woff') format('woff');
//}


#root {
  overflow: hidden;
}

@font-face {
  font-family: 'RobotoCustom';
  font-style: normal;
  font-weight: normal;
  src: local('RobotoCustom'), url('../fonts/roboto-regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  src: local('Inter.woff'), url('../fonts/Interregular.woff') format('woff');
}

@font-face {
  font-family: 'Oswaldlight';
  font-style: normal;
  font-weight: normal;
  src: local('Oswaldlight'), url('../fonts/Oswaldlight.woff') format('woff');
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans'), url('../fonts/DMSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: normal;
  src: local('Mulish'), url('../fonts/Mulish-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat'), url('../fonts/montserrat.woff') format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');

body {
  //font-family: RobotoCustom, serif;
  font-family: 'Ubuntu', sans-serif;;
  //font-family: Roboto, serif;
  //min-width: 800px; /* Suppose you want minimum width of 1000px */
  //width: auto !important; /* Firefox will set width as auto */
  //width: 800px; /* As IE6 ignores !important it will set width as 1000px; */
  margin: 0;
  padding: 0;
}

h1, h2 {
  color: $primaryColor;
}

.dropZone {
  .header {
    font-size: 1.7rem;
    margin: 10px 0 15px 0;
  }

  .container {
    .dropzone {
      //border: 1px solid red;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: #eeeeee;
      border-style: dashed;
      background-color: #fafafa;
      color: #bdbdbd;
      outline: none;
      transition: border .24s ease-in-out;
      cursor: pointer;
    }

    aside {
      h4 {
        //font-size: 1.3rem;
        margin: 10px 0 15px 5px;
      }
    }
  }

  .select {
    margin-bottom: 15px;
  }

  .btn-submit {
    margin-top: 15px !important;
  }
}

.filter-panel {
  margin-top: 15px;
  display: flex;
  flex-flow: row nowrap;
}

.custom-scroll {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  width: 100%;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}

.krkn__portal {
  //height: 0 !important;
}

.krkn__tail {
  position: absolute;
  z-index: 10001;
}

.krkn__dropdown__opened,
.krkn__multi-dropdown__opened,
.krkn__grouped-dropdown__opened,
.krkn__datepicker-overlay,
.krkn__multi-dropdown__opened,
.bkdui-modal-open {
  .krkn__tail {
    position: absolute;
    z-index: 10001;
  }
}

//@animation__default: cubic-bezier(0.23, 1, 0.32, 1);
//@animation__default-duration: 0.18s;

.animation__header-menu-enter {
  opacity: 0;
  transform: translateY(-10%);
}

.animation__header-menu-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: all 0.24s cubic-bezier(0.23, 1, 0.32, 1);
}

.animation__header-menu-exit {
  opacity: 1;
}

.animation__header-menu-exit-active {
  opacity: 0;
  transition: opacity 0.24s cubic-bezier(0.23, 1, 0.32, 1);
}

.CookieConsent {
  a {
    margin-left: 0.3em;
    color: #f40505;
    cursor: pointer;
  }
}

.global-snow-toggle {
  position: absolute;
  left: 15px;
  bottom: 15px;
  display: flex;
  flex-flow: row;
  align-items: center;

  label {
    color: white;
    margin-left: 5px;
  }
}