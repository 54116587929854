.agreement-page {
  background: linear-gradient(180deg, #030303 0%, #000000 100%);
  height: 100vh;
  .grad {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 856px;
  }

  .all-shit {
    position: absolute;
    top: 170px;
    //left: 700px;
    left: 0px;
    font-family: Montserrat, sans-serif;

    color: white;

    height: calc( 100vh - 170px );
    //width: calc(100% - 700px);
    width: calc(100% - 0px);

    display: flex;
    flex-flow: column nowrap;

    .header {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .content {
      display: flex;
      flex-flow: row nowrap;

      //font-size: 10px;
      height: 100%;
      .col {
        flex: 0 0 50%;
        position: relative;
        overflow: hidden;
        .morph {
          position: absolute;
          width: 668px;
          height: 858px;

          left: 20%;
          top: -100px;
          z-index: 1;
          mix-blend-mode: screen;
        }
        .kek {
          z-index: 100;
          padding: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .zalupa {
    position: absolute;
    left: 0.92%;
    right: 79.62%;
    top: 25.59%;
    bottom: 36.75%;
    width: 425px;
    height: 412px;

    mix-blend-mode: screen;
  }

  .zalupa2 {
    position: absolute;
    left: 300px;
    top: 381px;

    width: 338px;
    height: 360px;

    mix-blend-mode: screen;
  }

  .zalupa3 {
    position: absolute;
    width: 127.92px;
    height: 140.31px;
    left: 550.87px;
    top: 150.54px;
  }
  position: relative;
  .back-to-main-btn {
    //left: 67px;
    //top: 741px;

    background: #0047FF;
    border-radius: 10px;

    display: flex;
    @extend .centered-flex;

    font-family: RobotoCustom, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 46px;
    /* identical to box height, or 192% */

    position: sticky;
    bottom: 0;
    left: 0;

    color: #FFFFFF;

    z-index: 5000;
  }
}
