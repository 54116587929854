.basic-top-nav-bar {
  position: sticky;
  //height: 150px;
  //border: 1px solid red;
  top: 0;
  z-index: 6;
  display: flex;
  flex-flow: row wrap;
  user-select: none;
  //padding-top: 64px;
  //margin-top: 64px;
  padding-left: 43px;
  padding-right: 43px;
  //margin-left: 40px;
  //position: sticky;
  //top: 0;
  transition: opacity 0.4s;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.75);

  &-show {
    opacity: 1;
  }
  .header {
    text-transform: uppercase;
    display: flex;
    align-self: center;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 80px;
    line-height: 86px;
    /* identical to box height, or 107% */

    letter-spacing: 0.31em;

    color: #FFFFFF;
    width: fit-content;
    margin-right: auto;
  }
  .spacer {
    width: 100%;
  }

  .line {
    width: fit-content;
    display: flex;
    align-self: center;

    font-family: RobotoCustom, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    line-height: 54px;
    /* identical to box height */


    color: #FFFFFF;
  }


  .icon {
    width: 81px;
    height: 81px;
    display: flex;
    align-self: center;
  }

  .links {
    display: flex;
    flex-flow: row nowrap;
    //flex: 1 1 auto;
    //justify-content: flex-end;
    .link {
      margin: 0 30px;
      width: max-content;
      display: flex;
      align-self: center;

      font-family: RobotoCustom, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;

      color: #FFFFFF;
      @extend .bottom-border;

      .sign-up {
        border: 1px solid #6C6C6C;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 14px 40px;

        display: flex;
        flex-flow: row nowrap;

        transition: .25s;
        &:hover {
          background-color: white;
          color: black;
        }
      }
    }
  }

  .btn {
    padding: 5px 40px;
  }

  .btn-group {
    font-family: 'Rock Salt', cursive;
    margin-right: 50px;
    height: 48px;

    .user-login {
      @extend .centered-flex;
      margin-right: 25px;
    }

    .btn {
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}
