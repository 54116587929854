$transition: 0.1s;

.stats-page {
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  background-color: #f0f0f0;;
  width: 100%;

  &-block {
    display: flex;
    flex-flow: column;

    margin: 5px;
    padding: 15px;

    .header {
      @extend .centered-flex;
    }


  }
  .icons {
    display: flex;
    flex-flow: row nowrap;

    .info-block {
      color: #8997B1;
      background-color: #25262D;
      height: 150px;
      width: 150px;

      margin-right: 23px;

      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      .icon {
        flex: 1 1 auto;
        @extend .centered-flex;
        svg {
          height: 65px;
          width: 65px;
        }
        path {
          fill: #56657F;
          transition: $transition;
        }
      }

      .counter {
        font-family: RobotoCustom, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 36px;
      }

      .footer {
        font-family: RobotoCustom, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #56657F;
        margin: 5px 0 10px 0;
        transition: $transition;
      }
      transition: $transition;
      &:hover {
        background-color: #FD0000;
        cursor: pointer;
        color: lighten(#56657F, 50);
        .footer {
          color: lighten(#56657F, 50);
        }
        .icon {
          path {
            fill: lighten(#56657F, 50);
          }
        }
      }
    }
  }
  .chart {
    //height: 40%;
    //outline: 2px solid black;
  }

}
