.footer {
  //background-color: rgba(16, 16, 16, 1);
  height: 54px;
  //left: 0px;
  //top: 4599px;

  background: $primary_background;
  display: flex;
  flex-flow: row wrap;
  //justify-content: center;
  align-items: center;
  color: #FFFFFF;
  margin-top: auto;

  .line {
    font-size: 26px;
    line-height: 30px;
  }

  .icon {
    width: 50px;
    height: 50px;
  }

  .link {
    color: #FFFFFF;
    margin-left: 46px;
  }

  .payments {
    margin-left: auto;


    img {
      //width: 88px;
      height: 31px;
    }
  }

  .freekassa {
    //width: 88px;
    //height: 31px;
    margin-left: 15px;

    .img {
      //width: 88px;
      height: 31px;
    }
  }

  .info {
    color: #FFFFFF;
    margin-left: 46px;
    margin-right: 5px;
  }

  .lang-selector {
    width: unset;
    justify-content: unset;
    margin-left: 15px;
  }
}
