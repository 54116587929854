.plagins-install-page {
    font-size: 28px;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;

    .top {
        z-index: 501;
        padding-top: 5px;
        flex: 0 1 auto;
        @extend .centered-flex;
    }
    .content{
        display: flex;
        flex-flow: column;
        padding-top: 25px;
        .row {
            border-bottom: 1px solid #DFE0EB;
            &:first-child {
                border-top: 1px solid #DFE0EB;
            }
            display: flex;
            align-items: center;
            padding: 15px;

            .icon {
                flex: 0 1 50px;
                width: 50px;
                height: 50px;
            }

            .name {
                flex: 0 1 33%;

            }


            .other {
                flex: 0 1 66%;

            }

            .author, .toggle {
                flex: 0 1 33%;
                display: flex;
                justify-content: center;
            }
        }
    }
}

