.panel {
  $bg_clr: #25262D;
  border-radius: 25px;
  @extend .centered-flex;
  background-color: $bg_clr;
  width: 75%;
  height: 1.5em;
  text-transform: uppercase;
  .text-with-icon {
    color: white;
    display: flex;
    flex-flow: row nowrap;
    height: 1.5em;
    .text, .icon {
      font-size: 0.8em;
      @extend .centered-flex;
      &:hover {
        cursor: pointer;
      }
    }

    .icon {
      margin-left: 5px;
    }


  }

  position: relative;

  .btn {
    position: absolute;
    right: 0.1em;
    top: 0.1em;
    border-radius: 25px;
    background-color: white;
    color: black;
    height: 1.9em;
    width: 200px;
    padding: 0;
    margin: 0;
    font-size: 0.7em;
    box-shadow: 0 0 4px rgba(#000000,0.5);
  }

  &-active {
    justify-content: space-between;
    transition: all .15s ease;
    .text-with-icon {
      input {
        border: none;
        background-color: $bg_clr;
        border: 0;
        box-shadow: none;
        color: white;

        &:active, &:focus {
          border: none;
          background-color: $bg_clr;
          border: 0;
          box-shadow: none;
          color: white;
          outline: none;
        }
      }
      .icon {
        margin: 0 15px;
      }
    }
  }
}
