.custom-slider-container {
  width: 34px;
  height: 20px;
  position: relative;

  display: flex;
  align-items: center;
  transition: .3s;

  cursor: pointer;

  .custom-slider-body {
    background: $secondary_background;
    //background: rgb(217 174 174 / 26%);
    width: 100%;
    height: 14px;
    border-radius: 25px;
    transition: .3s;

  }

  .custom-slider-circle {
    background: #6B6B6B;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    height: 20px;
    width: 20px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    transition: .3s;
  }
  &--active {
    .custom-slider-body {
      background: #1A73E9;
      opacity: 0.24;
    }
    .custom-slider-circle {
      background: #5BC044;
      left: 50%;
    }
  }
}
