.border-left-block{
  display: flex;
  flex-direction: column;
  //margin: 25% 150px;
  width: 500px;

  .border-left {
    padding-left: 5px;
    border-left: 8px solid $redColor;

    .header {
      font-size: 1.1em;
      margin-bottom: 25px;
    }

    .description {
      line-height: 100%;
    }
  }

  .border-right{
    padding-right: 5px;
    border-right: 8px solid $redColor;
    text-align: right;

    .header {
      font-size: 1.1em;
      margin-bottom: 25px;
    }

    .description {
      line-height: 100%;
    }
  }
}
