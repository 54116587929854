.add-library-file-page {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  background-color: #E8E8E8;

  .list-of-files {
    display: flex;
    flex-flow: row wrap;

    &--item {
      margin: 5px;
      padding: 25px;
      background-color: white;
      flex: 0 1 15%;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-flow: column nowrap;
      .title {
        font-size: 1.3em;
      }
      .version, .title, .date {
        text-align: center;
      }
      .delete-btn {
        margin-top: 5px;
        @include btn-style(red);
        cursor: pointer;
        user-select: none;
        color: white;
        display: flex;
        justify-content: center;
        padding: 5px 0;

      }
      .download-btn {
        margin-top: 25px;
        @include btn-style(green);
        cursor: pointer;
        user-select: none;
        color: white;
        display: flex;
        justify-content: center;
        padding: 5px 0;
      }
    }
  }

  .upload-container {

    .container {
      .dropzone {
        //border: 1px solid red;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 20px;
        border-width: 2px;
        border-radius: 2px;
        border-color: #eeeeee;
        border-style: dashed;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border .24s ease-in-out;
        cursor: pointer;
      }

      aside {
        h4 {
          //font-size: 1.3rem;
          margin: 10px 0 15px 5px;
        }
      }
    }
  }

  .save {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;

    position: static;
    //width: 276px;
    height: 38px;
    //right: 62px;
    top: 124px;

    background: rgba(253, 0, 0, 0.7);
    border-radius: 4px;

    /* Inside Auto Layout */

    flex: none;
    order: 2;
    flex-grow: 0;
    //margin: 16px;
    justify-content: center;
    color: #FFFFFF;

    font-family: RobotoCustom, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;

    cursor: pointer;
    user-select: none;
    @include btn-style($redColor);
    &--disabled {
      @include btn-style(#797979);
      color: #f3ebeb;
    }
  }
}
