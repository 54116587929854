.lang-selector {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;

  &__click-catcher {
    padding: 5px 5px 1px 5px;
    //outline: 1px solid red;
    //background-color: #c0bcbc;
    transition: all .15s;
    &:hover {
      background-color: #ff8e25;
    }
    &--active {
      background-color: lighten(#ff8e25, 15);
    }

    svg {
      width: 30px;
      height: 20px;
    }
  }
}
