$primary_background: #202438;
$secondary_background: rgba(44, 50, 77, 1);
$primaryColor: #c2c2c2;
$descriptionColor: #969696;

@mixin border($property) {
  display: block;
  position: absolute;
  content: '';
  height: 2px;
  width: 100%;
  left: 0;
  bottom: -5px;
  background: $property;
}


@mixin btn-style($property) {
  //background-color: $property;
  cursor: pointer;
  padding: 16px 42px;
  box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.5);
  line-height: 1.25;
  background: $property;
  text-decoration: none;
  color: white;
  font-size: 16px;
  letter-spacing: .08em;
  text-transform: uppercase;
  position: relative;
  transition: background-color .6s ease;
  overflow: hidden;
  user-select: none;
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform-style: flat;
    transform: translate3d(-50%, -50%, 0);
    background: rgba(white, .1);
    border-radius: 100%;
    transition: width .3s ease, height .3s ease;
  }

  &:focus,
  &:hover {
    background: darken($property, 7%);
  }

  &:active {
    &:after {
      width: 200px;
      height: 200px;
    }
  }
}
