.reset-password-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  //width: fit-content;
  margin-left: auto;
  margin-right: auto;
  background: $primary_background;
  width: 100%;
  padding: 0 20%;
  color: white;
  .reset-password-page__title {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    width: 100%;
    text-align: center;
  }

  .reset-password-page__content {
    width: 100%;
  }

  .reset-password-page__action {
    margin-top: 25px;
    width: 100%;

    .ui.button {

    }
  }
}


.reset-password-error-confirm-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  //width: fit-content;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  //width: 50%;
  background: $primary_background;
  color: white;
  padding: 0 25%;
  &__title {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 25px;
    //width: 100%;
  }

  &__content {
    //width: 100%;
  }

  &__action {
    margin-top: 25px;
    width: 100%;

    .ui.button {

    }
  }
}

.forgot-password-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  background-color: $primary_background;
  color: white;
  width: 100%;
  //padding: 0 100%;
  //width: 50%;

  &__title {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    //margin-bottom: 25px;
    //width: 100%;
  }

  &__content {
    width: fit-content;
    .form__field{
      color: white;
    }
    //
  }

  &__action {
    margin-top: 25px;
    width: 250px;

    .ui.button {

    }
  }
}
