@function vh($quantity) {
  @return calc(var(--vh, 1vh) * #{$quantity});
}

.about-page {
  //height: 100vh;
  width: 100%;
  font-size: $base-font-size;
  color: $whiteColor;
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  user-select: none;

  background: linear-gradient(180deg, #030303 0%, #000000 100%);
  height: vh(100);
  .basic-top-nav-bar {
    width: 100%;
    height: fit-content;

    .header {
      //width: 150px;
      white-space: nowrap;
    }

    .spacer {
      //display: none;
    }

    .links {
      margin-left: auto;

      .link {
        &:first-child {
          display: none;
        }
      }
    }
  }

  .gradient {
    position: absolute;
    right: 0;
    top: 0;
  }

  .pudge {
    position: absolute;
    width: 959px;
    height: 767px;
    left: 881px;
    top: 074px;
  }

  .blue {
    position: absolute;
    width: 338px;
    height: 360px;
    left: 1551px;
    bottom: 5px;
  }

  .text {
    position: absolute;
    width: 788px;
    height: 276px;
    left: 40px;
    top: 361px;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 65px;
    /* or 164% */


    color: #FFFFFF;
  }

  .back-to-main-btn {
    position: absolute;
    width: 540px;
    height: 79px;
    left: 67px;
    top: 741px;

    background: #0047FF;
    border-radius: 10px;

    display: flex;
    @extend .centered-flex;

    font-family: RobotoCustom, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 46px;
    /* identical to box height, or 192% */


    color: #FFFFFF;
    transition: 0.2s;
    &:hover {
      background: darken(#0047FF, 15);

    }
  }
}
