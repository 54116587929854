.term_of_use__container, .privacy__container {

  background: linear-gradient(180deg, #030303 0%, #000000 100%);

  .react-pdf {
    &__Document {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__Page {
      max-width: 100%;
      box-shadow: 0 0 8px rgba(0, 0, 0, .5);
      margin: 1em;

      canvas {
        max-width: 100%;
        height: auto !important;
      }
    }

    &__message {
      padding: 20px;
      color: white;
    }
  }
}

.contacts__container {

  background: linear-gradient(180deg, #030303 0%, #000000 100%);
  height: 100vh;
  .contacts__container-table {
    margin: 0px 25%;
  }
}
