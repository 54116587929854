.test_page {
  height: 100vh;
  display: flex;
  flex-flow: row wrap;
  background-color: white;
  &__block {
    outline: 1px solid lightslategray;
    height: fit-content;
    flex: 0 1 auto;

    padding: 5px 10px;
    margin: 5px;
    &__header {
      border-bottom: 1px solid lightslategray;
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      &__forms {
        display: flex;
        flex-flow: row;
        .form {
          margin: 5px;
        }
      }
      &__result {
        display: flex;
        flex-flow: column nowrap;
        &__data {
          display: flex;
          flex-flow: column nowrap;
          div {
            &:hover {
              background-color: lightslategrey;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
