.dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  user-select: none;
  width: 100%;
  height: 100%;
  //@extend .centered-flex;
  &-btn {
    //z-index: 2;
    width: 100%;
    height: 100%;
    @extend .centered-flex;
  }

  &-content {
    position: absolute;
    //border: 1px solid black;
    background-color: darken(rgba(#25262D, 1), 25);
    margin-top: 40px;
    width: 200px;
    //bor: 25px;
    //padding: 5px 20px;
    //border-bottom-left-radius: 15px;
    //border-bottom-right-radius: 15px;
    //padding: 5px;
    z-index: 5000;
  }

  &-item {
    padding: 15px 25px;
    width: 100%;
    color: white;



    &-content {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      .icon {
        transition: .4s;
        opacity: 0;
        transform: translateX(-35px) rotate(
                        0deg
        );
      }
    }

    &:hover {
      background-color: #166ACC;
      .dropdown-item-content {

        .icon {
          opacity: 1;
          transform: translateX(0) rotate(
                          360deg
          );
        }
      }
    }
  }
}
