.sign-in-callback-page {
  background: $primary_background;
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .sign-in-callback-page__container {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .sign-in-callback-page__container__error {
    border: 1px solid #dfdfdf;
    padding: 25px;
    border-radius: 10px;
    max-width: 700px;
    //margin-top: 50px;

    display: flex;
    flex-flow: column;
    align-items: center;

    button {
      margin-top: 30px;
    }
  }
}