.forum_page {
  color: white;
  max-width: 1200px;
  width: 100%;

  .forum_page__header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .forum_page__title {
      font-size: 2rem;
      font-weight: bold;
    }
  }

  .forum_page__wrapper {
    border: 1px solid white;
    padding: 25px;
    border-radius: 4px;
    //background: $primary_background;
    background: $secondary_background;

    .forum_page__theme--simulate {
      width: 100%;
    }

    .forum_page__theme {
      border: 1px solid white;
      padding: 10px 25px;
      border-radius: 4px;
      margin-bottom: 15px;
      background: $primary_background;

      cursor: pointer;
      transition: 0.25s all;

      &:hover {
        background: lighten($primary_background, 15);
      }

      .forum_page__theme__item__wrapper {
        display: flex;
        flex-flow: column nowrap;
        //justify-content: space-between;
        //align-items: center;

        .forum_page__theme__title {
          font-size: 1.5rem;
        }

        .forum_page__theme__info {
          font-size: 0.8rem;
          color: $descriptionColor;
        }

        .forum_page__theme__action, .forum_page__theme__info {
          display: flex;
          flex-flow: row nowrap;

          .forum_page__theme__info__author {
            margin-right: 10px;
          }
        }

        .forum_page__theme__wrapper__row {
          &:last-child {
            justify-content: space-between;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
          }
        }
      }

      .forum_page__theme__wrapper {
        display: flex;
        flex-flow: column nowrap;
        //justify-content: space-between;
        //align-items: center;
        &--simulate {
          min-height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .forum_page__theme__wrapper__row {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;

          .forum_page__theme__action {
            margin-left: auto;
          }
        }

        .forum_page__theme__title {
          font-size: 1.4rem;
        }

        .forum_page__theme__description {
          font-size: .9rem;
          line-height: 1rem;
          //color: $descriptionColor;

          margin-right: 10px;
        }

        .forum_page__theme__action__date {
          margin-right: 10px;
          color: $descriptionColor;
          font-size: .8rem;
          line-height: 1rem;
        }
      }
    }
  }


}

.forum_page__theme_content {
  color: white;
  max-width: 1200px;
  width: 100%;



  .forum_page__theme_content__wrapper {
    border: 1px solid white;
    padding: 25px;
    border-radius: 4px;
    //background: $primary_background;
    background: $secondary_background;
    position: relative;
    &.forum_page__comments {
      margin-top: 15px;

      .forum_page__theme_content__pagination{
        display: flex;
        justify-content: end;
      }
    }

    .forum_page__theme_content__description {
      .forum_page__theme_content__description__input {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: 500px;
        max-height: 5000px;
      }
    }

    .forum_page__theme_content__title {
      font-size: 2rem;
      margin-bottom: 30px;
      font-weight: bold;

      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
    }

    .forum_page__theme_content__comments {
      font-size: 2rem;
      margin: 30px 0;
      border-top: 1px solid white;
      padding-top: 15px;
    }

    .forum_page__theme_content__header {
      display: flex;
      flex-flow: row nowrap;
      color: $descriptionColor;
      font-size: .8rem;
      line-height: 1.2rem;
      .forum_page__theme_content__title__header__author {
        margin-right: 15px;
      }
    }

    .forum_page__theme_content__actions {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .forum_page__theme_content__input__wrapper {
      position: relative;
      .forum_page__theme_content__input {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: 150px;
        max-height: 300px;
      }
      .forum_page__theme_content__input__button{
        //position: absolute;
        //bottom: 0px;
        //right: 25px;
      }
    }


  }

  .forum_page__theme_content__comment_wrapper {
    margin: 50px 0;
    &--hidden {
      //border: 1px solid #4f4d4d;
      //padding: 25px;
    }
    .comment__top {
      margin-bottom: 5px;
      position: relative;
      .comment__top__hidden {
        color: red;
      }
      .comment__top__actions {
        position: absolute;
        top: 5px;
        right: 5px;
      }
      .comment__top__date {
        color: $descriptionColor;
        font-size: .8rem;
        line-height: 1rem;
      }
    }
    .comment__content {
      .comment__content__input {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: 150px;
        max-height: 300px;
      }
    }

  }
}

.create_forum_theme_theme_area {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 150px;
  max-height: 300px;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}


.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
