.local-plugins-page-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  position: relative;

  .left-panel {
    flex: 0 0 340px;
    width: 340px;
    border-right: 1px solid white;
    height: fit-content;
    margin-left: 15px;
    padding-right: 64px;

    display: flex;
    flex-flow: column nowrap;

    .input {

      height: 48px;
      margin-bottom: 41px;
      input {
        background: #161616;
        backdrop-filter: blur(43px);
        color: white;
      }
      .search.icon {
        &:before {
          color: white;
        }
      }
    }

    .link {
      color: rgba(104, 104, 104, 1);
      font-weight: bold;
      font-size: 25px;
      line-height: 24px;
      &.active {
        color: #FFFFFF;
      }
      & + .link {
        margin-top: 49px;
      }
    }
  }

  &--content {
    //background-color: #166ACC;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    width: 100%;
    margin: 0px 25px;
    //.header {
    //  font-size: 28px;
    //}

    .local-plugins-page-container--content--area {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .local-plugins-page-container--content--col {
        &:first-child {
          width: 950px;
        }
      }

      .local-plugins-page-container--content--col + .local-plugins-page-container--content--col {
        margin-left: 25px;
      }
    }

    .form__group.field {
      //width: 400px;
      label {
        user-select: none;
      }
      input {
        color: white;
      }
    }

    .add-btn {
      margin-top: 25px;
      margin-left: 5px;
      width: fit-content;
      height: 40px;

      box-shadow: 0px 17px 33px rgba(61, 223, 47, 0.35);

      &:hover {
        box-shadow: 0px 17px 33px rgba(61, 223, 47, 0.35);
      }
    }

    .local-plugin-item-container {
      //margin-top: 25px;
      padding-top: 25px;
      flex: 0 1 100%;
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      height: 100%;
      //outline: 2px solid red;

      .local-plugin-item {
        padding: 25px;
        margin: 5px;
        //outline: 1px solid yellowgreen;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-flow: column nowrap;
        align-content: center;
        justify-content: space-between;
        color: white;
        background: #1A1A1A;
        border-radius: 15px;
        .header {
          overflow: hidden;
          height: fit-content;
          word-break: break-all;
          font-size: 20px;
          padding-bottom: 50px;
        }

        .action {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;

          .compile {
            background: #353535;
            color: white;
            margin-bottom: 25px;
          }

          button {
            margin-right: 0;
            & + button {
              margin-left: 15px;
            }
          }
        }

        &--disabled {
          //background-color: #9EA2A9;
          //outline: 1px solid red;
        }
      }
    }
  }
}
