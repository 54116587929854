.transaction_item {
  display: flex;
  flex-flow: row nowrap;
  padding: 0px 5px;
  margin: 1px;

  cursor: default;
  user-select: none;

  &__icon {
    background-color: #def2da;
    border-radius: 50%;
    //padding: 5px;
    font-size: 18px;
    line-height: 24px;
    width: 24px;
    height: 24px;
    @extend .centered-flex;
    color: #5BC044;
    margin: 10px 0px;
  }

  &__content {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: inset 0px -0.5px 0px #E8E8E8;
    height: 100%;
    padding: 10px 0px;
    div {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
    &__text {
      font-size: 18px;
      line-height: 20px;
    }

    &__price {
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
    }

    &__date {
      color: #9EA2A9;
      font-size: 16px;
      line-height: 18px;
    }
  }
}
