.success_payment_page {
  @extend .centeredFlex;
  height: 100vh;
  background-color: #25262D;
  color: white;
  .success_payment_page__container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    //outline: 1px dotted white;
    //padding: 25px 50px 5px 50px;
    padding: 150px;
    .status_icon {
      margin-bottom: 20px;
    }
    .container__text {
      font-size: 25px;
      line-height: 30px;
      font-weight: bold;
      &.success {
        color: #21ba45;
        margin-bottom: 20px;
      }
    }
    .container__text_2 {
      font-size: 20px;
      line-height: 25px;
    }
    .container__success {
      margin-top: 5px;
    }
    .container__sub {
      margin-top: 5px;
      font-size: 10px;
      line-height: 15px;
      color: #989a9b;
    }
    .container__loader {
      position: relative;
      height: 50px;
    }
    .container__btn {
      margin-top: 20px;
    }
  }
}
