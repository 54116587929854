.plugin-manager-page {
  &__list {
    background-color: white;

    &__item {
      border-bottom: 1px solid black;
      padding: 5px 15px;
      //margin: 5px;
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      &__info_area {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        //flex: 0 1 auto;
        width: 100%;

        &__row {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          width: 100%;
        }

        &_name {
          //border: 1px solid red;
        }

        &_author, &_time, &_description {
          margin-left: 15px;
          color: #9EA2A9;
          flex: 0 1 auto;
          width: fit-content;
        }

        &_description {
          overflow-wrap: break-word;
          flex: 1 1 500px;
        }

        &_path{
          overflow-wrap: break-word;
          //flex: 1 1 500px;
        }

      }

      &_local {
        //flex: 1 1 auto;
        @include btn-style(#b3ab21);
        margin-left: auto;
        padding: 5px 10px;
      }

      &_decline {
        //flex: 1 1 auto;
        @include btn-style(#db0f0f);
        margin-left: 5px;
        padding: 5px 10px;
      }

      &_approve {
        //flex: 1 1 auto;
        @include btn-style(#67be2f);
        margin-left: 5px;
        padding: 5px 10px;
      }
    }
  }
}
