.buy_subscribe_page {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 100px;
  max-width: 1350px;
  width: 100%;
  &__title_block {
    display: flex;
    flex-flow: row nowrap;
    //margin: 0 100px;
    justify-content: center;
    width: 100%;
    .title_block--text {
      font-weight: bold;
      font-size: 35px;
      line-height: 60px;
      color: white;
      flex: 0 0 487px;
      display: flex;
      align-items: center;
      padding-bottom: 40px;
    }
    .title_block--icon {
      width: 753px;
      //height: 500px;
    }
  }

  &__subscribe_block {
    margin: 50px 0px 150px 0;
    display: flex;
    flex-flow: column nowrap;
    //align-items: center;
    //width: 1350px;
    width: 100%;
    .subscribe_block--title {
      font-size: 28px;
      line-height: 24px;
      color: white;
      align-self: flex-start;
    }
    .subscribe_block--content {
      margin-top: 16px;
      display: flex;
      flex-flow: column nowrap;
      //align-items: center;
      color: white;

      .content {
        display: flex;
        flex-flow: row nowrap;
        .price-info {
          //border: 1px solid #E8E8E8;
          max-width: 270px;
          width: 100%;
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-around;
          padding: 5px;
          height: 46px;
          //color: black;
          color: white;
          //background: #FFFFFF;
          //border: 1px solid #E8E8E8;
          box-sizing: border-box;
          border-radius: 2px;
          //box-shadow: 2px 2px 15px rgba(#E8E8E8, 2);
          margin-right: 23px;
          cursor: default;
          .info {
            font-size: .8em;
            //color: #ABABAB;
            line-height: 1.0em;
          }

          .prices {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
          }

          .price {
            font-size: 1.5em;
            line-height: 1.1em;
            font-weight: bold;
            &--discount {
              //font-weight: unset;
              //color: yellowgreen;
            }
          }
        }

        button {
          & + button {
            margin-left: 20px;
          }
          background-color: white;
          color: black;
          height: 70%;
          justify-self: center;
          align-self: center;
          display: flex;
          padding: 5px 15px;
          font-size: 14px;
          line-height: 15px;
          box-shadow: 0px 17px 33px rgba(255, 255, 255, 0.2);
          align-content: center;
          justify-content: center;
          align-items: center;
        }
      }
      .content--slider {

        margin-top: 20px;
      }

      .congratz {
        margin-top: 50px;
        font-size: 20px;
        line-height: 25px;

        display: flex;
        flex-flow: column;

        .congratz__tooltip {
          font-size: 15px;
          line-height: 20px;
          color: slategray;
        }
      }
    }
  }

  &__sale_block {
    display: flex;
    flex-flow: column nowrap;
    color: white;
    margin-top: 120px;
    width: 100%;
    .sale_block--title {
      font-size: 28px;
      line-height: 32px;
    }
    .sale_block--content {
      .progress-bar {
        .bar {
          border: unset;
          background-color: white;
          padding: 0;
          .bar-inside {
            background-color: orange;
            height: 5px;
          }
        }
      }
      .sale-percents {
        display: flex;
        flex-flow: row nowrap;
        .percent {
          flex: 1 0 auto;
          display: flex;
          justify-content: flex-end;
        }
        .image {
          flex: 0 0 20%;
          display: flex;
          justify-content: center;
          align-items: center;
          .dis {
            width: 103px;
            height: 103px;
          }
          .slark {
            width: 178px;
            height: 120px;
          }
          .razor {
            width: 187.08px;
            height: 111px;
          }
          .void {
            width: 188.71px;
            height: 123px;
          }
          .sf {
            width: 193.59px;
            height: 104px;

          }
        }
      }
    }
  }
  &__info_block {
    margin: 120px 0;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    color: white;
    justify-content: space-between;
    .info_block {
      display: flex;
      flex-flow: column nowrap;
      flex: 0 0 40%;
      &--title {
        background: #FFFFFF;
        border-radius: 25px;
        padding: 4px 14px;
        color: black;
        @extend .centered-flex;
        width: fit-content;
        letter-spacing: 0.21em;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 9px;
        line-height: 20px;
        user-select: none;
      }
      &--description {
        font-weight: bold;
        font-size: 30px;
        line-height: 32px;
        margin-top: 15px;
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .buy_subscribe_page__title_block {
      .title_block--text {
        flex: unset;
      }
      .title_block--icon {
        display: none;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .buy_subscribe_page__title_block {

      .title_block--icon {
        display: none;
      }
    }
    .buy_subscribe_page__sale_block {
      .sale_block--content {
        .sale-percents {
          .image {
            display: none;
          }
        }
      }
    }
    .buy_subscribe_page__info_block {
      flex-flow: column wrap;
      .info_block {
        & + .info_block {
          margin-top: 25px;
        }
      }
    }
  }
}

.popup-paypal-content {
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  .paypal-buttons {
  }
}


