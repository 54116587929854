.log-message {
  cursor: pointer;
}

.line-break {
  padding: 5px;
  background-color: white;
  code {
    white-space: pre-wrap;
  }
}
