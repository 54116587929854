.centeredFlex {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.hotkey {
  width: 100%;
  user-select: none;

  .input {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    background-color: #FFFFFF;
    border: none;
    color: white;
    cursor: pointer;
    outline: 3px solid #2d9cdb;
  }

  .hotkey-container {
    display: flex;
    flex-flow: row nowrap;
    background-color: #FFFFFF;
    height: 50px;
    padding: 0 15px;
    cursor: pointer;
    outline: 1px solid #E8E8E8;

    .hotkey-label {
      padding-left: 5px;
      @extend .centeredFlex;

      text-wrap: none;
    }

    .hotkey-tooltip {
      margin-left: auto;
      padding-right: 5px;
      @extend .centeredFlex;

      &--hover {
        display: none;
        text-wrap: none;
      }
    }


    &:hover {
      .hotkey-tooltip {
        display: none;

        &--hover {
          margin-left: auto;
          padding-right: 5px;
          @extend .centeredFlex;
        }
      }
    }
  }
}
