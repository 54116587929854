.page__invalid-time{
	height: 100vh;
	width: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	.page__invalid-time__message{
		font-size: 22px;
		line-height: 30px;
		font-weight: bold;
		text-align: center;
		max-width: 70vw;

	}

	img {
		height: 70%;
		//height: 500px;
	}
	.icon{
		margin-right: 8px;
	}
}
