.users-page {
  font-size: 28px;
  width: 100%;
  &-top {
    //font-size: 1rem;
    margin: 20px;
    display: flex;
    flex-flow: row nowrap;
    height: 1.5em;
    //background-color: white;
    input, .header {
      color: white;
    }
    .btn {
      border-radius: 0;
      height: 1.5em;
      .btn-content {
        display: flex;
        height: 1.5em;
        flex-flow: row nowrap;
        @extend .centered-flex;
        svg {
          margin-right: 5px;
        }
      }
    }
  }

  &-list {
    //display: flex;
    //flex-flow: row wrap;
    //@extend .centered-flex;
    //width: 500px;
    height: calc(100vh - 42px * 2 - 100px);
    margin: 50px;
    .user-item {
      flex: 0 0 100px;
      height: 100px;
      //width: 260px;
      //border: 1px solid red;
      //margin: 15px;

      display: flex;
      flex-flow: column nowrap;
      font-size: 0.8em;
      @extend .centered-flex;
      background-color: #E8E8E8;
      position: relative;
      outline: 1px solid black;
      .line {
        &-name {
          //font-weight: bold;
          //margin-bottom: 20px;
        }

        &-id {
          //font-size: .7em;
          //text-align: center;
        }
        &-icon {
          height: 100px;
          width: 100px;
          border-radius: 50px;
          border: 2px solid black;
          margin-bottom: 20px;
        }
      }

      .settings-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
      }
    }
  }
}
.user-container {
  display: flex;
  flex-flow: column nowrap;
  padding: 15px;
  min-height: 500px;
  .title {
    @extend .centered-flex;
  }
  .btn-group {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .sub-block {
    display: flex;
    flex-flow: column nowrap;
    .sub-block--form {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-evenly;
      margin-bottom: 15px;
    }
    align-self: center;

    > span {
      margin-bottom: 15px;
    }

    button {
      margin-top: 25px;
    }
  }
}
