$color__main: #575e95;
$color__accent: #ff9700;
$color__error: #e81010;
$color__disabled: #e0e0e0;

$color__bg: #fff;
$color__bg--block: #eaeaea;
$color__bg--disabled: #808080;
$color__bg--hover: #eceef4;

$color__font: #333;
$color__font--additional: #808080;
$color__font--disabled: #33333377;

$color__link__text: #575e95;
$color__link__text--hover: #e58800;

$color__link__icon: #575e95;
$color__link__icon--hover: #ff9700;

$color__btn__bg: #ff9700;
$color__btn__bg--hover: #ffb100;

$color__control__bg: #ff9700;
$color__control__font: $color__bg;
$color__control__bg--disabled: #e0e0e0;
$color__control__font--disabled: #8a8a8a;
$color__control__border: #808080;
$color__control__border--hover: #ff9700;
$color__control__border--focus: #4e5589;
$color__control__border--has-content: #545573;
$color__control__border--disabled: #e0e0e0;
$animation__default: cubic-bezier(0.23, 1, 0.32, 1);
$animation__default-duration: 0.18s;

$color__block__bg: #f9f9f9;
.public-plugins-page {

  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  position: relative;

  .left {
    padding-top: 5px;
    z-index: 501;
    flex: 0 1 auto;
    @extend .centered-flex;
  }

  .left-panel {
    flex: 0 0 340px;
    width: 340px;
    border-right: 1px solid white;
    height: fit-content;
    margin-left: 15px;
    padding-right: 64px;

    display: flex;
    flex-flow: column nowrap;

    .input {

      height: 48px;
      margin-bottom: 41px;

      input {
        background: #161616;
        backdrop-filter: blur(43px);
        color: white;
      }

      .search.icon {
        &:before {
          color: white;
        }
      }
    }

    .link {
      color: rgba(104, 104, 104, 1);
      font-weight: bold;
      font-size: 25px;
      line-height: 24px;

      &.active {
        color: #FFFFFF;
      }

      & + .link {
        margin-top: 49px;
      }
    }
  }

  .content {
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    //align-items: flex-start;
    align-content: flex-start;
    justify-content: center;

    .item {
      width: 428px;
      height: 263px;
      background: #0D0E16;
      border: 2px solid darken(#FFFFFF, 50);
      box-sizing: border-box;
      border-radius: 34px;
      //background-color: $secondary-background;
      margin: 18px 21px;
      //padding: 19px;
      font-family: RobotoCustom, serif;;
      display: flex;
      flex-flow: column nowrap;
      color: white;
      overflow: hidden;
      &__content {
        flex: 1;
        background: #0D0E16;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 0 15px 10px 15px;
        position: relative;
        text-shadow: 1px 1px 1px #000;
        &__name,&__author {
          margin-top: auto;
          z-index: 50;

        }
        &__name {
          font-size: 1.5rem;
          cursor: pointer;
        }
        &__background {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0%;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 0%);
          margin: 0 auto;
        }
        &__logo {
          position: absolute;
          width: 62px;
          height: 45px;
          top: 5px;
          right: 10px;
          user-select: none;
        }
        &__count_of_users {
          position: absolute;
          width: 37px;
          height: 40px;
          top: 15px;
          left: 15px;
          user-select: none;
          display: flex;
          flex-flow: row nowrap;
        }
      }

      &__actions {
        display: flex;
        flex-flow: row nowrap;

        &__item {
          flex: 1;
          display: flex;
          justify-content: center;
          font-size: 14px;
          line-height: 18px;
          padding: 15px 0;
          cursor: pointer;
          user-select: none;
          transition: 0.25s all;
          &:first-child {
            background: rgba(0, 0, 0, 0.1);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(100px);
            &:hover {
              background: rgba(100, 100, 100, 0.2);
            }
          }
          &:last-child {
            background: rgba(0, 255, 133, 0.1);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(100px);
            &:hover {
              background: rgba(0, 255, 133, 0.2);
            }
          }
        }
      }
      &--enabled {
        border: 2px solid #FFFFFF;
        .item__actions {
          .item__actions__item {
            &:last-child {
              background: rgba(218, 33, 33, 0.23);
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              backdrop-filter: blur(100px);
              &:hover {
                background: rgba(218, 33, 33, 0.43);
              }
            }
          }
        }
      }
    }
  }

  .description-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-flow: column nowrap;
    //justify-content: flex-end;
    z-index: 999999;

    .empty-space {
      flex: 1 1 auto;
    }

    .description {
      //position: absolute;
      //bottom: 0;
      //left: 0;
      background-color: white;
      width: 100%;
      display: flex;
      //position: sticky;
      flex-flow: column nowrap;


      .top {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        margin-bottom: 25px;

        .close-icon {
          padding: 24px;
          user-select: none;
          cursor: pointer;
        }

        .plugin-name {
          margin-left: 24px;
        }
      }

      .mini-block {
        margin-bottom: 15px;
        margin-left: 8px;

        .header {
          font-family: RobotoCustom, serif;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 28px;
          margin-left: 16px;
          color: rgba(0, 0, 0, 0.87);
          user-select: none;
        }

        .text {
          font-family: RobotoCustom, serif;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 20px;
          margin-left: 16px;
          color: rgba(0, 0, 0, 0.87);
          opacity: 0.54;


        }

        &--with-height {
          height: 92px;

          .text {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;

            overflow: hidden;
            word-wrap: anywhere;
          }

          .show-more-icon {
            margin-right: 15px;
          }
        }
      }

      .enable-block {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin: 0 24px;
        font-family: RobotoCustom, serif;
        font-style: normal;
        font-size: 20px;
        line-height: 21px;
      }

      .close-button-wrapper {
        //max-width: 250px;
        position: relative;
        background: linear-gradient(to top, rgba(26, 115, 233, 1), rgba(142, 103, 190, 1));
        padding: 1px;
        margin: 50px 24px;
        //height: 40px;

        border-radius: 6px;
        opacity: 0.32;
        transition: .25s;

        &:hover {
          opacity: 0.75;
        }
      }

      .close-button {
        background: white;
        color: black;
        padding: 1rem;
        opacity: 1;
        //padding: 2rem;
        //border: 1px solid rgba(142, 103, 190, 1);
        //margin: 50px 24px;
        //height: 40px;
        //
        font-family: RobotoCustom, serif;
        font-style: normal;
        font-weight: 650;
        font-size: 22px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        justify-content: center;

        user-select: none;

        cursor: pointer;

        border-radius: 6px;
      }
    }
  }
}

.plugin__settings {
  background-color: white;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);

  display: flex;
  flex-flow: column nowrap;
  padding: 5px 5px;

  .plugin__settings__option {
    padding: 2px 4px;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: darken(white, 15);
    }
  }
}

.edit_content {
  padding: 10px;
  display: flex;
  flex-direction: row;
  min-height: 70vh;

  &__editor, &__viewer {
    flex: 0 0 50%;
    max-width: 50%;

    TextArea {
      min-width: 100%;
      max-width: 100%;

      max-height: 100%;
      min-height: 100%;
      border: none;
      overflow: auto;
      outline: none;

      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      resize: none; /*remove the resize handle on the bottom right*/

    }
  }

  &__editor {
    border-right: 1px solid black;
  }
}

.rc-scrollbars-thumb.rc-scrollbars-thumb-v {
  background-color: #f0f0f070 !important;
}
@media screen and (max-width: 900px) {
  .public-plugins-page {
    .content {
      justify-content: unset;
    }
  }
}
@media screen and (max-width: 820px) {
  .public-plugins-page {
    flex-flow: column nowrap;

    .left-panel {
      width: unset;
      border-right: unset;
      border-bottom: 1px solid white;
      margin-bottom: 15px;
      padding-bottom: 15px;
      flex: 0 0 auto;
    }
  }
}

.plugin_overview {
  //backdrop-filter: blur(43px);
  //border-radius: 25px;
  padding: 50px;
  color: white;
  position: relative;
  &__name {
    position: absolute;
    top: 30px;
    left: 15px;
    font-size: 36px;
    line-height: 46px;
  }
  &__logo {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 357px;
    height: 133px;
  }
  display: flex;
  flex-flow: column;
  align-items: center;
  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
    width: 100%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    //filter: blur(7px);
    //height: 1500px;
  }

  &__title, &__description__title {
    font-size: 36px;
    line-height: 46px;
    margin-top: 61px;
  }

  &__description {
    width: 100%;
    .box {
      display: inline-block;
      padding: 10px;
      box-sizing: border-box;
    }
    .img-preview {
      overflow: hidden;
    }

    .edit_content {
      display: flex;
      flex-flow: column;
      width: 100%;
      .edit_content__editor {
        width: 100%;
        max-width: 100%;
        //height: 500px;
        flex: 1;
      }
      .edit_content__viewer {
        flex: 1;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &__action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(100px);

    cursor: pointer;
    user-select: none;
    transition: 0.25s all;
    width: 220px;
    &--edit {
      border-radius: 34px 34px 0px 0px ;
      margin-top: 35px;
      background: rgba(213, 189, 36, 0.67);
      &:hover {
        background: rgba(213, 189, 36, 0.87);
      }
    }
    &:first-child {
      &--close {
        margin-top: 35px;
      }
    }

    &--close {
      margin-top: 35px;
      border-radius: 0px 0px 34px 34px;
      background: rgba(255, 0, 0, 0.67);
      &:hover {
        background: rgba(255, 0, 0, 0.9);
      }
    }
    &--edit ~ &--close {
      margin-top: 0;
    }
  }
}

.krkn__modal-portal {
  .krkn__modal-wrapper {
    .image-gallery-dialog {
      max-width: 620px;
      .krkn__modal-header {
        text-align: left;
      }
    }
  }
}

.image_uploader {
  label {
    cursor: pointer;
    .icon {
      margin-right: 5px;
    }
  }
}
.map-page {
  .krkn__modal-portal {
    .krkn__modal-overlay.image_editor-overlay {
      background-color: #000;
      opacity: 0.5;
    }


  }
}

.krkn__modal-portal {
  //font-family: lato, 'Segoe UI', sans-serif;
  .krkn__modal-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    //background-color: #57595c;
    background-color: #000;
    opacity: 0.6;
    z-index: 100;
  }
  .krkn__modal-wrapper {
    display: flex;
    // flex-direction: column;
    // justify-content: center;
    align-items: center;
    padding: 50px 0;
    overflow: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10001;
    //padding: 40px;
    .krkn__modal {
      margin: 104px auto;
      //flex: 0 1 auto;
      //z-index: 12;
      opacity: 1;
      padding: 10px;
      color: $color__font;
      background-color: $color__bg;
      border: 1px solid $color__main;
      width: 100%;
      min-height: 100px;
      position: relative;
      max-width: 820px;
      .krkn__modal-header__close {
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 8px;
        height: 24px;
        width: 24px;
        cursor: pointer;
        .icon__svg {
          fill: $color__main;
          width: 10px;
          height: 10px;
          &:hover {
            fill: $color__btn__bg--hover;
          }
        }
      }
      .krkn__modal-header {
        //width: 100%;
        color: #545573;
        display: flex;
        justify-content: center;
        text-align: center;
        //padding-right: 20px;
        //padding: 12px 24px 12px 24px;
        font-weight: 700;
        //background-color: #f4f4f4;
        //margin: -10px -10px 0 -10px;
        font-size: 16px;
        //border: 1px solid cornflowerblue;
      }
      .krkn__modal-content {
        padding: 16px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .krkn__modal-footer {
        //background-color: #edecf2;
        //padding: 20px 10px;
        //margin: 0 -10px -10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .reject-button,
        .approve-button {
          height: 28px;
          margin-left: 8px;
        }
        .krkn__modal-footer__controls {
          .krkn__modal-footer__control {
            margin-right: 8px;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
body {
  overflow: inherit;
  &.modal-open {
    overflow: hidden;
  }
}
//#region back-confirmation dialog

.krkn__modal-portal {
  .krkn__modal-wrapper {
    bottom: unset;
    top: 0;
    padding: 0;
    overflow: inherit;
    .krkn__modal {
      &.modal__confirmation {
        padding: 0;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        .krkn__modal-header {
          margin: 0;
          height: fit-content;
          padding: 0 0 0 17px;
          color: #545573;
        }
        .krkn__modal-content {
          flex: 1 1 auto;
          font-size: 14px;
          line-height: 17px;
        }
        .krkn__modal-footer {
          border-top: 1px solid #f3f3f3;
          //padding: 20px 10px 10px 10px;
          margin: 0;
          //background-color: #fff;
          //.btn {
          //    margin: 0 10px;
          //}
        }
      }

      &.modal__back-confirmation,
      &.modal__confirmation {
        margin-top: 104px;
        min-height: 117px;
        max-height: 520px;
        width: 538px;
        .krkn__modal-header {
          justify-content: flex-start;
          height: 56px;
          align-items: center;
        }
        .krkn__modal-content {
          align-items: flex-start;
        }
        .krkn__modal-footer {
          height: 48px;
          .btn {
            margin: 0 10px;
          }
        }
      }

      .krkn__editable-table {
        .add-button {
          cursor: pointer;
          .icon {
            width: 15px;
            height: 15px;
          }
        }

        .col-first {
          padding-left: 0px;
        }

        .remove-button-container {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .remove-icon {
            cursor: pointer;
            width: 15px;
            height: 15px;
          }
          .content {
            width: 90%;
          }
        }
      }

      .krkn__modal-footer {
        .btn {
          margin: 0 10px;
        }
      }

      &.modal__invalid-crypto-setup {
        max-width: 600px;
        ul {
          margin-top: 8px;
          list-style-type: "\2013";
          list-style-position: inside;
        }
        .krkn__modal-content {
          margin-bottom: 10px;
        }
      }
      &.modal__select-road-short-form {
        max-width: 650px;

        .krkn__modal-header {
          justify-content: flex-start;
          margin: -10px -10px 0 -10px;
          background-color: #f4f4f4;
        }
        .krkn__modal-content {
          margin-bottom: 4px;
          .modal__select-road-short-form__title {
            width: 100%;
            font-weight: 900;
            background-color: #f4f4f4;
            text-align: left;
            padding: 2px;
          }
          .krkn__radio-group {
            .radio {
              padding: 12px 0;
            }
          }
        }
      }

      &.modal__cube-settings {
        height: 500px;
        display: flex;
        flex-direction: column;
        .placeholder {
          position: absolute;
          border: 2px dashed #c6c2ce;
          //background-color: #c6c2ce;
          //height: 20px;
          //margin: 2px 0;
        }
        .krkn__modal-content {
          flex: 1 1;
          width: 100%;

          .cube-settings__content {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;

            .cube-settings__section {
              padding: 4px 0;
              flex: 1 1 50%;
              display: flex;
              flex-direction: column;
              .cube-settings__section-header {
                margin-bottom: 12px;
                .cube-settings__section-header-title {
                  color: #6b6572;
                }
              }
              .cube-settings__section-content-wrapper {
                overflow: hidden;
                width: 100%;
                flex: 1 1;
                .cube-settings__section-content {
                  padding: 4px 8px;
                  overflow-x: hidden;
                  position: relative;
                  .cube-settings__section-block {
                    margin-bottom: 12px;
                    position: relative;
                    .cube-settings__section-block-title {
                      color: #333333;
                      font-weight: 900;
                    }
                    .cube-settings__section-block-content {
                      position: relative;
                    }
                    .cube-item {
                      display: flex;
                      align-items: center;
                      padding: 4px 2px;
                      user-select: none;
                      cursor: pointer;
                      .cube-item__icon {
                        margin-right: 8px;
                        margin-bottom: -2px;
                        .icon {
                          width: 12px;
                          height: 12px;
                          //margin-top: 2px;
                        }
                      }
                      .cube-item__name {
                      }
                      &.cube-item--empty {
                        color: #33333380;
                      }
                      &:hover {
                        transition: all 0.24s $animation__default;
                        background-color: #e3e3e3;
                        &.cube-item--empty {
                          cursor: default;
                          background-color: inherit;
                        }
                      }
                    }
                  }
                }
              }

              &.cube-settings__section--current {
                padding-left: 20px;
                background-color: #f4f4f4;
              }
            }
          }
        }
      }
      &.modal__indicator-settings {
        height: 500px;
        display: flex;
        flex-direction: column;
        max-width: 700px;

        .placeholder {
          position: absolute;
          border: 2px dashed #c6c2ce;
          //background-color: #c6c2ce;
          //height: 20px;
          //margin: 2px 0;
        }
        .krkn__modal-content {
          flex: 1 1;
          width: 100%;
          .indicator-settings__content {
            width: 100%;
            height: 100%;
            overflow: hidden;
            .indicator-settings__section-group {
              display: flex;
              width: 100%;
              margin-bottom: 4px;
              .indicator-settings__section {
                flex: 1 1;
                display: flex;
                flex-direction: column;
                min-height: 100px;
                max-width: 50%;
                margin-right: 20px;

                .indicator-settings__section-title {
                  width: 100%;
                  background-color: #f4f4f4;
                  font-weight: 900;
                  padding: 4px 8px;
                }
                .indicator-settings__section-content-wrapper {
                  padding: 8px 16px;
                  position: relative;
                  .indicator-settings__section-content {
                    width: 100%;

                    position: relative;
                    .krkn__radio-group-item {
                      margin-bottom: 2px;
                    }
                    .krkn__checkbox {
                      padding: 2px 0;
                    }
                  }
                }

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

//#region

.bkdui-modal-overlay {
  z-index: 100;
}
.bkdui-modal {
  z-index: 100;
  .bkdui-form-field {
    width: 100%;
    .krkn__dropdown,
    .krkn__multi-dropdown {
      width: 100%;
    }
    .krkn__input {
      width: 100%;
    }
  }

  .bkdui-table-field {
    &.bkdui-form-field {
      width: 100%;
      .krkn__input {
        .krkn__input__content {
          border-bottom: none;
        }
      }
      .krkn__dropdown {
        .krkn__dropdown__content {
          border-bottom: none;
        }
      }
      .krkn__multi-dropdown {
        .krkn__dropdown__content {
          border-bottom: none;
        }
      }
    }
  }
}

//#region Download Multiple Files

.modal__download-multiple-files {
  .modal__download-multiple-files__content {
    width: 100%;
  }
  .modal__download-multiple-files__title {
    padding-bottom: 20px;
    color: $color__main;
    //font-size: 14px;
    font-weight: bold;
  }
  .modal__download-multiple-files__file-block {
    font-size: 13px;
  }
  .modal__download-multiple-files__file-block-title {
    //font-size: 14px;
    font-weight: bold;
  }
  .modal__download-multiple-files__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
  }
  .modal__download-multiple-files__preview-link {
    font-size: 12px;
  }
}

.image-gallery-dialog {
  .krkn__images-gallery {
    max-width: 500px;
  }

  .image-gallery-dialog-content {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    .image-gallery-dialog-icon {
      position: absolute;
      left: 8px;
    }
  }
  .image-gallery-dialog-files {
    .krkn__images-gallery {
      .krkn__images-gallery-wrapper {
        .image-gallery-thumbnail img {
          width: 92px;
          height: 72px;
        }
        .image-gallery-slide img {
          width: 500px;
          height: 400px;
        }
      }
    }
  }
}
.krkn__modal-portal {
  .krkn__modal-wrapper {
    .image-gallery-dialog {
      max-width: 620px;
      .krkn__modal-header {
        text-align: left;
      }
    }
  }
}

//#endregion
.krkn__modal-portal {
  .krkn__modal {
    &.modal__select-user-org {
      min-height: 650px;

      .krkn__modal-header {
        padding: 6px 10px;
        text-align: left;
      }
      .krkn__modal-content {
        height: 100%;
        min-height: 550px;
      }
      .krkn__modal-footer {
        background-color: #f4f4f4;
        .krkn__modal-footer__controls {
          .krkn__btn {
            height: inherit;
            border-radius: 16px;
            padding: 8px 32px;
          }
        }
      }
    }
  }
}
.krkn__select-user-org--content {
  min-height: 550px;
}
.krkn__select-user-org {
  flex-grow: 1;
  height: 0;
  .user-selector__row {
    &--selected {
      background-color: #e1e0e7;
      .krkn__cell .toggle-icon {
        background-color: #e1e0e7;
        &::before {
          background-color: #e1e0e7;
        }
      }
      &:hover {
        background-color: #e1e0e7;
      }
    }
  }

  .organizations-only {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .search-input {
      width: 100%;
      margin-bottom: 4px;
      border-bottom: 1px solid #00000029;
    }
  }

  .organizations-with-groups {
    flex-grow: 1;
    height: 100%;
    position: relative;

    .search-input {
      position: absolute;
      right: 0;
      top: 0;
      width: 400px;
      border-bottom: 1px solid #00000029;
    }

    .select-user-org__panes {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .menu {
        display: inline-block;
        border-bottom: 2px solid #edecf2;
        margin-bottom: 8px;

        .item {
          cursor: pointer;
          display: inline-block;
          margin-right: 24px;
          margin-bottom: -2px;
          padding: 4px 0;
          user-select: none;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            cursor: default;
            border-bottom: 2px solid #682048;
            font-weight: bold;
          }
        }
      }
    }

    .tab {
      height: 100%;
    }

    .users-by-group {
      .col.col__favorites {
        .krkn__cell__content-wrapper {
          justify-content: center;
        }
      }
    }
  }

  .user-hierarchy-table {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .krkn__cell__content {
      position: relative;
      .count {
        color: #9a9a9a;
        font-size: 90%;
        margin-left: 8px;
        display: inline-block;
      }
    }
  }
}

.krkn__modal-portal {
  .krkn__modal-wrapper {
    .krkn__modal.krkn__wizard {
      .krkn__modal-header {
        background-color: $color__block__bg;
        font-size: 16px;
        text-align: left;
      }
      .krkn__wizard__title {
        padding: 8px 0;
      }
      .krkn__modal-content {
        align-items: flex-start;
        justify-content: flex-start;
      }
      .krkn__modal-footer {
        background-color: $color__block__bg;
      }
    }
  }
}

.krkn__wizard__steps {
  display: flex;
  align-items: center;
  .krkn__wizard__steps__separator {
    width: 40px;
    height: 0;
    border-top: 1px solid $color__control__border;
    margin-right: 8px;
  }
  .krkn__wizard__steps__item {
    margin-right: 8px;
    color: $color__control__font--disabled;
    font-weight: bold;
    &--active {
      color: $color__control__font;
    }
  }
}

.krkn__modal-portal {
  .krkn__modal-wrapper {
    .change-history-dialog {
      max-width: 860px;
      max-height: 500px;
      height: 500px;
      min-height: 500px;
      .krkn__modal-header {
        .title {
          text-align: left;
          font-size: 16px;
          letter-spacing: 0px;
          color: #333333;
        }
      }
      .krkn__modal-content {
        .entity-list.entity-list__change-history {
          .entity-list__filters {
            .entity-list__filters-content {
              .krkn__filters-panel {
                @media screen and (max-width: 1680px) {
                  .filter__start_date_finish_date {
                    display: flex;
                  }
                }
                .krkn__filters-panel__bottom-content {
                  .krkn__filters-panel__bottom-content-wrapper {
                    .krkn__filters-panel__groups {
                      .krkn__filters-panel__group {
                        .krkn__filters-panel__group-fields {
                          .filter__initiator {
                            flex: 1 1;
                            max-width: 240px;
                            .krkn__multi-dropdown {
                              width: 230px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .entity-list__filters-count {
              flex: 0 0 220px;
            }
          }
          .entity-list-fixed {
            position: unset;
            width: 800px;
          }
          .entity-list-content {
            .change-history-table {
              .krkn__row {
                &:hover {
                  background: white;
                }
                .krkn__cell {
                  &:hover {
                    cursor: default;
                    .krkn__cell__content-wrapper {
                      color: unset;
                    }
                  }
                  .krkn__cell__content-wrapper {
                    .krkn__cell__content {
                      .change-history-table__row {
                        .change-history-table__columns {
                          display: flex;
                          .col__date {
                            flex: 0 0 200px;
                            .spacer {
                              flex: unset !important;
                            }
                            .krkn__row__vertical-line {
                              top: 20px !important;
                            }
                            display: flex;
                            align-items: center;
                            .toggle-icon {
                              margin-right: 8px;
                              &.toggle-icon--expanded {
                                transform: rotate(180deg);
                              }
                            }
                          }
                          .col__source {
                            flex: 0 0 200px;
                          }
                          .col__user {
                          }
                        }
                        .change-history-table__actions {
                          padding-left: 20px;
                          padding-top: 20px;
                          padding-bottom: 8px;
                          .rc-table.bkdui-table-ex {
                            .rc-table-content {
                              .rc-table-thead {
                                .col__text {
                                  text-align: left;
                                  word-break: break-word;
                                }
                                .col__action {
                                  text-align: center;
                                }
                              }
                              .rc-table-tbody {
                                .col__text {
                                  text-align: left;
                                  word-break: break-word;
                                }
                                .col__action {
                                  margin: 0 auto;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.krkn__modal.purchase_road_work_selector__modal {
  max-width: 1024px !important;
  .krkn__modal-header {
    justify-content: flex-start !important;
    display: flex;
    align-items: center;

    .krkn__modal-header__title {
      margin-right: 10px;
    }
  }
  .krkn__modal-content {
    height: 500px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .col {
    &--type {
      .krkn__cell__content-wrapper {
        justify-content: center !important;
      }
    }
    &--cost {
      .krkn__cell__content-wrapper {
        justify-content: flex-end !important;
      }
    }
    &--deadline {
      .krkn__cell__content-wrapper {
        flex-direction: column;
      }
    }
    &--power {
      .krkn__cell__content-wrapper {
        flex-direction: column;
      }
    }
  }
  .event_name {
    position: relative;
    left: -70px;
  }
  .entity-list__controls-search {
    padding-left: 0 !important;
  }
  .entity-list__filters-count {
    flex-basis: 160px !important;
  }
  .krkn__stats-panel__title {
    flex-direction: column;
  }
  .selected_counter {
    min-width: 130px;
    text-align: right;
  }
}



.krkn__modal-portal {
  .krkn__modal-wrapper.image_uploader-wrapper {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: rgba(0, 0, 0, .9);
    //filter: blur(4px);
    .image_uploader_modal {
      //width: 70%;
      //height: 28vw;

      //min-width: 70%;
      //min-height: 28vw;

      //max-width: 70%;
      //max-height: 28vw;

      background-color: transparent;
      border: unset;
      margin-bottom: 0;
      //padding-bottom: 0;
      margin-top: 0;
      width: 500px;
      height: 300px;
      .image_uploader_modal__container {

        width: 100%;
        height: 100%;
        padding: 20px;
        border: 5px solid #E0E0E0;
        background: black;

        .image_uploader_modal__preview {
          //width: 428px;
          //height: 211px;
          float: right;
          display: inline-block;
          padding: 10px;
          box-sizing: border-box;
          overflow: hidden;
        }
      }
    }

    .image_uploader__hint {
      max-width: 600px;
      text-align: center;
      color: #FFFFFF;
      font-size: 12px;
      line-height: 20px;
      margin-top: 2%;
      margin-bottom: 6%;
      user-select: none;
    }

    .image_uploader__actions {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;

      .button {
        margin: 0 10px;
      }
    }
  }
}
