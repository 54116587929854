.add-plugins-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  //padding: 15px;
  .left-panel {
    flex: 0 0 340px;
    width: 340px;
    //border-right: 1px solid white;
    height: fit-content;
    margin-left: 15px;
    padding-right: 64px;

    display: flex;
    flex-flow: column nowrap;

    .input {

      height: 48px;
      margin-bottom: 41px;
      input {
        background: #161616;
        backdrop-filter: blur(43px);
        color: white;
      }
      .search.icon {
        &:before {
          color: white;
        }
      }
    }

    .link {
      color: rgba(104, 104, 104, 1);
      font-weight: bold;
      font-size: 25px;
      line-height: 24px;
      &.active {
        color: #FFFFFF;
      }
      & + .link {
        margin-top: 49px;
      }
    }
  }
  &--content {
    display: flex;
    flex-flow: column nowrap;
    height: 100vh;
    background-color: #E8E8E8;
    width: 100%;
  }
  &--header {
    display: flex;
    flex-flow: row nowrap;
    padding: 25px;
    align-items: center;
    background-color: #FFFFFF;
    .title {
      font-family: RobotoCustom, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      /* identical to box height, or 125% */


      color: #2F3A4A;
      margin-right: auto;
    }

    .btn {
      @include btn-style(#2792e5);
      border-radius: 5px;
      margin-right: 0px;
      transform: scale(0.8);
      &--cancel {
        @include btn-style(#E8E8E8);
        color: black;
      }
      &--accept {
        @include btn-style(rgba(253, 0, 0, 0.7));
      }
      &--disabled {
        @include btn-style(rgb(212, 207, 207));
        cursor: no-drop;
      }
    }

    .tooltip {
      margin-right: 50px;
      display: flex;
      flex-flow: row nowrap;
      .red {
        color: red;
        margin-right: 3px;
      }
    }
  }

  &--container {
    display: flex;
    flex-flow: row nowrap;

    height: 100%;

    .left-part, .right-part {
      flex: 1 1 auto;
      //outline: 1px solid red;
      height: 100%;
      max-width: 50%;
      .block {
        padding: 20px 24px;
        background-color: #FFFFFF;
        margin: 10px 5px 10px 0;
        height: 198px;

        &--header {
          font-family: RobotoCustom, serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          display: flex;
          align-items: center;

          color: #2F3A4A;
        }

        &--content {
          margin-top: 20px;


          .category-picker {
            display: flex;
            flex-flow: row nowrap;

            .category-picker__item {
              color: black;
              padding: 10px 15px;
              margin: 5px;
              background: #FFFFFF;
              border: 1px solid #E8E8E8;
              box-sizing: border-box;
              border-radius: 20px;

              cursor: pointer;
              &:hover {
                background: darken(#FFFFFF, 5);
              }
              &--selected {
                background: darken(#FFFFFF, 15);
                &:hover {
                  background: darken(#FFFFFF, 15);
                }
              }

              &-rounded {

              }
            }

          }

          .subtext {
            font-family: RobotoCustom, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            /* identical to box height, or 125% */
            overflow-wrap: break-word;

            color: #ABABAB;
          }

          .input, input {
            //margin-top: 16px;
            //width: 100%;
            //height: 46px;
            //padding: 0 15px;
            //background: #FFFFFF;
            //border: 1px solid #E8E8E8;
            //box-sizing: border-box;
            //border-radius: 2px;
            &::placeholder {
              //font-family: RobotoCustom, serif;
              //font-style: normal;
              //font-weight: normal;
              //font-size: 10px;
              //line-height: 12px;
              /* identical to box height */


              //color: #ABABAB;
            }
          }

          .textarea {
            margin-top: 16px;
            width: 100%;
            height: 80px;
            //padding: 0 15px;
            background: #FFFFFF;
            border: 1px solid #E8E8E8;
            box-sizing: border-box;
            border-radius: 2px;
            max-width: 100%;
            min-width: 100%;
            &::placeholder {
              font-family: RobotoCustom, serif;
              font-style: normal;
              font-weight: normal;
              //font-size: 10px;
              //line-height: 12px;
              /* identical to box height */


              color: #ABABAB;
            }
          }
        }
      }
    }
  }
}
