.html_editor_wrapper {
  .html_editor_toolbar {
    //background: $primary_background;
    //border: unset;
    .rdw-dropdownoption-default {
      color: black;
    }
  }
  .html_editor_editor {

    background-color: $primary_background;
    min-height: 150px;
    &--view-mode {
      background-color: unset;
      min-height: unset;
    }
  }

}




.page.modals {
  .html_editor_editor {
    background-color: unset;
  }
}