$primary: #11998e;
$secondary: #38ef7d;
$white: black;
$gray: #E8E8E8;
.form__group {
  position: relative;
  padding: 15px 0 0 5px;
  margin-top: 10px;
  width: 100%;
  //border: 2px solid $gray;

  &--error {
    //border-bottom: 1px solid red;
    &:before {
      @include border(#cc3a3a);
      animation: one-time-animation .5s forwards 1;
    }
  }
}

@keyframes one-time-animation {
  0%, 100% {
    @include border(#cc3a3a);
  }
  50% {
    @include border(lighten(#cc3a3a, 25));
  }
}

.form__field {
  font-family: inherit;
  width: 100%;
  //border: 0;
  //outline: 0;
  border: 1px solid $gray;
  font-size: 1.3rem;
  color: $white;
  padding: 7px 5px;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 25px;
  }
}

.form__label {
  position: absolute;
  top: -4px;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: darken($gray, 25);
  margin-left: 5px;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: -4px;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $primary;
    font-weight:700;
  }
  //padding-bottom: 6px;
  font-weight: 700;

  //outline-width: 3px;
  //border-width: 3px;
  //border-image: linear-gradient(to right, $primary,$secondary);
  //border-image-slice: 1;
}
/* reset input */
.form__field{
  &:required,&:invalid { box-shadow:none; }
}
