.logs-page {
  $sub_clr: #166ACC;
  font-size: 28px;
  height: calc(100% - 15px);
  display: flex;
  flex-flow: column nowrap;
  .top {
    flex: 0 1 2.5rem;
    display: flex;
    flex-flow: row nowrap;
    margin: 25px;

    user-select: none;
    //height: 3rem;
    .title {
      @extend .centered-flex;
      font-weight: bold;
    }


    .total {
      font-size: medium;
      text-align: left;


    }

    .spacer {

    }

    .page-settings {
      display: flex;
      flex-flow: row nowrap;

      svg {
        height: $base-font-size;
        width: $base-font-size;
      }

      .sort {
        margin-right: 15px;
        @extend .centered-flex;
      }

      .filter {
        @extend .centered-flex;
      }
    }


    .btn-clear {
      //margin-top: 25px;
      //padding: 15px 25px;
      width: 350px;
      border-radius: 0px;
      height: 100%;
      background-color: #E8E8E8;
      color: black;
      margin-right: 20px;
      //margin-right: 15px;
    }
  }

  .table-container {
    flex: 1 1 auto;
    //outline: 1px solid black;
    margin: 0 15px;
    //height: calc(100% - 100px);
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    .table {
      width: 100%;
      font-size: .8em;
      table-layout: fixed;
      border-collapse: collapse;
      //border: 1px solid black;
      thead {
        user-select: none;
        tr {
          border-bottom: 1pt solid black;

          th {
            color: #9FA2B4;
            height: 50px;

            &:first-child {
              width: 45%;
            }

            &:last-child {
              width: 15%;
            }
          }
        }
      }

      tbody {
        font-size: .8em;
        tr {
          cursor: pointer;
          &:hover {
            background-color: lighten(#9EA2A9, 30%);
          }
        }
        td {
          padding: 5px 15px;
          border-bottom: 1pt solid #DFE0EB;

          &.message{

            word-break: break-all;
          }
          &:first-child {
            width: 45%;
            color: $sub_clr;
          }

          &:last-child {
            width: 15%;
          }

          .col-container {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            height: 4rem;
            width: 100%;

            .time, .plugin-author {
              color: $sub_clr;
            }
          }

          &.centered {
            text-align: center;
          }

          &.level {
            user-select: none;
            padding-right: 10px;
            .level-round {
              border-radius: 25px;
              width: 150px;
              background-color: red;
              height: 2em;
              @extend .centered-flex;
              //margin-right: 25px;
              &-info {
                background-color: #166ACC;
                color: white;
              }
              &-debug {
                background-color: slategray;
              }
              &-error {
                background-color: darken(red, 5);
                color: white;
              }
              &-crash {
                background-color: red;
                color: white;
              }
              &-trace {
                background-color: lightslategray;
                color: white;
              }

            }
          }

          //border: 1px solid black;
        }
      }
    }

    .loading {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, .5);
      text-align: center;
      vertical-align: center;
      padding: 25% 0 0 0;
      color: white;
      z-index: 500;
    }
  }
}
