.need-to-confirm {
  background-color: #0A0A0A;
  height: vh(100);
  width: 100vw;
  color: white;

  &__wrapper {
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-around;

    flex-flow: row nowrap;
    max-width: 1500px;
    margin: 0 auto;
    &__image {
      width: Min(25vw, 500px);
    }
    &__content {
      width: Min(40vw, 700px);
      background: rgba(26, 26, 26, 1);
      padding: 40px 25px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      &__image {
        border: 5px solid #DB0C26;
        border-radius: 50%;
        padding: 15px 4px;
        //width: 62.88px;
        //height: 40.32px;
      }

      .need-to-confirm__description {
        text-align: center;
      }
    }

    .need-to-confirm__title {
      //font-size: 25px;
      //font-weight: bold;

      //margin-bottom: 25px;

      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 64px;
      line-height: 96px;
      text-align: center;
    }

    .need-to-confirm__mail {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      &__hint {
        color: #DB0C26;
        margin-left: 0.5rem;
      }
    }

    .need-to-confirm__actions {
      margin-top: 15px;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
    }
  }
}

@media screen and (max-width: 1200px) {
  .need-to-confirm {
    //width: 90vw;
    &__wrapper {
      margin: 0 auto;
      width: 90vw;
      &__content {
        width: 100%;
      }
      &__image {
        display: none;
      }
      .need-to-confirm__title {
        font-size: 40px;
        line-height: 50px;
      }
    }
  }
}