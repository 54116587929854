.video-content-page {
  height: 100vh;
  width: 100%;
  font-size: $base-font-size;
  color: $whiteColor;
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  user-select: none;

  //background-image: url('~background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  position: relative;

  flex-flow: column nowrap;

  .border-left-block {
    margin: 50px 0 0 calc(15% + 50px);
    .border-left {
      border-left: none;
    }
  }

  .video-container {
    display: flex;
    flex-flow: row wrap;
    margin: 10px 15%;
    @extend .centered-flex;
    .video-block-item {
      border-radius: 25px 0;
      flex: 0 1 380px;
      height: 210px;
      //border: 1px solid white;
      margin: 25px;
      background-color: #C4C4C4;
      color: black;
      @extend .centered-flex;
    }
  }
}
