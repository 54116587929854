.expander-container {
  .header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 15px;
    height: 50px;
    margin: 0 24px 0 32px;
    font-family: RobotoCustom, sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: normal;
    //font-size: 11px;
    line-height: 13px;
    /* identical to box height */


    color: #B9C1CE;

    .toggle-icon {
      margin-right: 15px;
      svg {
        transition: transform 0.35s ;
        transform: rotate(180deg);
        @extend .centered-flex;
      }
      &-active {
        svg {
          transform: rotate(0deg);
        }
      }
    }

    cursor: pointer;

    &:hover {
      //background-color: lighten(#25262D, 15);
    }
  }

  .content {
    display: flex;
    flex-flow: column nowrap;

    .expanded-item {
      display: flex;
      flex-flow: row nowrap;
      padding-left: 15px;
      margin: 0 16px;
      border-radius: 4px;
      height: 48px;
      align-items: center;

      font-family: RobotoCustom, serif;
      font-style: normal;
      font-weight: 500;
      font-size: .9em;
      line-height: 22px;
      /* identical to box height, or 147% */

      letter-spacing: 0.01em;

      color: #778192;

      .icon {
        margin-right: 15px;

        svg {
          path {
            fill: #778192;
          }
        }
        img {
          width: 22px;
          height: 22px;
        }
      }

      cursor: pointer;
      color: #778192;

      &:hover {
        //background-color: lighten(#25262D, 15);
        color: #F2F2F2;

        svg {
          path {
            fill: #F2F2F2;
          }
        }
      }

      &--active {
        background-color: lighten(#25262D, 15);
        color: #F2F2F2;

        svg {
          path {
            fill: #F2F2F2;
          }
        }

        &:hover {

        }
      }
    }
  }
}
