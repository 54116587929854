.account-page {
  display: flex;
  flex-flow: row wrap;
  height: 100vh;
  background-color: #E8E8E8;
  .block {
    padding: 20px 24px;
    background-color: #FFFFFF;
    margin: 10px 5px;
    height: fit-content;
    &__header {
      font-family: RobotoCustom, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      display: flex;
      align-items: center;

      color: #2F3A4A;
    }

    &__content {
      margin-top: 20px;
      //outline: 1px solid red;
      .btn {
        @include btn-style(#EB5757);
        //width: 84px;
        height: 26px;
        font-size: 12px;
        line-height: 14px;
        border-radius: 1px;
        width: fit-content;

        margin-top: 15px;
        margin-left: 5px;

        &--disabled {
          @include btn-style(#c1bebe);
        }
      }
    }

    &__token {

      .block__content {
        display: flex;
        flex-flow: column nowrap;
      }
      .btn {
        @include btn-style(#EB5757);
        //width: 84px;
        height: 26px;
        font-size: 12px;
        line-height: 14px;
        border-radius: 1px;
        width: fit-content;

        margin-top: 15px;
        margin-left: 5px;

        &--disabled {
          @include btn-style(#c1bebe);
        }
      }
    }

    &__lang {
      .block__content {
        display: flex;
        justify-content: center;
        align-items: center;
        //.click-catcher {
        //  svg {
        //    width: 30px;
        //    height: 20px;
        //  }
        //}
      }
    }

    &__token {

    }
    &__change_password {
      .block__content {
        &__input {
          //outline: 1px solid red;
          display: flex;
          flex-flow: row nowrap;
          //align-items: flex-start;
          .form__group.field {
            //padding: 0 0 0 5px;
            margin-top: 0;
          }
        }
      }
    }
    &__email {
      position: relative;
      .text {
        background: #25262D;
        border-radius: 4px;
        font-family: RobotoCustom, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        /* identical to box height, or 100% */

        text-decoration-line: underline;

        /* sidebar/white */

        color: #FFFFFF;
        padding: 5px 10px;
      }
      .btn {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

.account-page__modal {

}
