.block {
  position: relative;
  background-color: #ffffff;
  display: flex;
  flex-flow: column nowrap;
  padding: 20px;
  margin: 5px;
  box-shadow: 0 0 4px rgba(#E8E8E8, 1);

  &__header {
    display: flex;
    justify-content: space-between;
    font-size: .6em;
    font-weight: 500;
    line-height: .8em;
    &__title {

    }

    &__action {

    }
  }

  &__content {
    font-size: .5em;
    height: 100%;
    padding-top: 16px;
  }
}
