.progress-bar {
  padding: 5px;
  .title {
    @extend .centered-flex;
    font-size: 20px;
  }
  .bar {
    margin-top: 5px;
    //background-color: green;
    border-radius: 25px;
    border: 1px solid white;
    /* (height of inner div) / 2 + padding */
    padding: 3px;
    .bar-inside {
      background-color: white;
      height: 13px;
      border-radius: 10px;
      transition: .2s;
    }
  }
}
