.cloud-config-page {
  //display: flex;
  //flex-flow: row wrap;
  //padding: 15px;
  //height: 100%;
  //width: 100%;
  ////justify-content: flex-start;
  ////align-content: flex-start;
  //background-color: #0A0A0A;

  //background-color: #0A0A0A;
  display: flex;
  flex-flow: column nowrap;
  max-width: 1600px;

  &--content {
    display: flex;
    flex-flow: column nowrap;
    //background-color: #0A0A0A;
  }

  .configs-browser {
    width: 100%;
    //flex: 0 0 670px;
    height: fit-content;
    //border-right: 1px solid white;
    padding: 15px;

    &--title {
      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
      color: #FFFFFF;
      margin-bottom: 15px;
    }

    &--search {
      width: 100%;

      .input {
        width: 100%;
      }

      margin-bottom: 15px;
    }

    &--list {
      width: 100%;
      //border: 1px solid black;
      //height: 200px;
      height: 220px;

      &--content {
        display: flex;
        flex-flow: row nowrap;

        .config-item {
          width: 200px;
          height: 200px;
          flex: 0 0 200px;
          border: 1px solid black;
          margin: 3px;
          padding: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-flow: column nowrap;
          background: linear-gradient(360deg, rgba(34, 34, 34, 0.84) 35.94%, rgba(34, 34, 34, 0) 100%);
          border-radius: 14px;
          border: 1px solid white;

          position: relative;

          .backImage {
            position: absolute;
            opacity: 0.5;
            z-index: 1;
            width: 100%;
            //padding: inherit;
            left: 0;
            top: 0;
            height: 100%;
            user-select: none;
            -webkit-user-drag: none;
            //filter: blur(4px);
          }

          &--title {
            font-weight: bold;
            font-size: 15px;
            line-height: 20px;
            z-index: 2;

            /* identical to box height, or 107% */


            /* Gray / Light */

            color: #CDCDCD;
          }

          &--author {
            font-weight: bold;
            font-size: 9px;
            line-height: 20px;
            /* identical to box height, or 222% */

            letter-spacing: 0.21em;
            text-transform: uppercase;

            color: #FFFFFF;
            z-index: 2;

          }

          &--actions {
            z-index: 2;
            button {
              //background-color: white;
              //color: black;
            }
          }
        }
      }


    }
  }

  .config-settings {
    //background-color: white;
    //flex: 1 0 500px;
    display: flex;
    flex-flow: row wrap;
    color: white;
    align-items: center;
    .lang-selector {
      width: fit-content;
      height: fit-content;
      margin-left: 15px;
    }

    .hotkey {
      //width: 33%;
      //flex: 0 1 250px;
      flex: 0 1 25%;
      width: 250px;
      padding: 25px;

      &-container {
        background-color: #1A1A1A;
        border-radius: 25px;
        border: unset;
        outline: unset;
      }

      input {
        background-color: #1A1A1A;
        border-radius: 25px;
        border: 3px solid #2d9cdb;
        outline: unset;
      }
    }

    .input-block {
      padding: 25px;
      display: flex;
      flex-flow: column nowrap;
      //width: 100%;
      flex: 0 1 100%;


      input {
        color: white;

      }

      .base-input {
        height: 50px;
        //padding: 0 15px;
        //background: #FFFFFF;
        background: #1A1A1A;

        border: 1px solid #E8E8E8;
        box-sizing: border-box;
        border-radius: 2px;
        padding: 0 15px;
        &::placeholder {
          font-family: RobotoCustom, serif;
          font-style: normal;
          font-weight: normal;
          color: white;
          //font-size: 10px;
          //line-height: 12px;
          /* identical to box height */


          color: white;
        }
      }
    }

    .launch {
      //width: 33%;
      flex: 0 1 100%;
    }

    .toggle {
      //width: 15%;
      flex: 0 1 150px;

      .react-toggle {
        width: 50px;
      }
      &.full {
        flex: 0 1 200px;
      }
      .dropdown {
        height: fit-content;
        border: 1px solid white;
        cursor: pointer;
      }
      &--long {
        flex: 0 1 185px;
      }
    }

    .save {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px;

      position: static;
      width: 276px;
      height: 38px;
      //right: 62px;
      top: 124px;

      background: rgba(253, 0, 0, 0.7);
      border-radius: 4px;

      /* Inside Auto Layout */

      flex: none;
      order: 2;
      flex-grow: 0;
      margin: 16px 0px 16px 25px;
      justify-content: center;
      color: #FFFFFF;

      font-family: RobotoCustom, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;

      cursor: pointer;
      user-select: none;
      @include btn-style($redColor);
      //&:hover {
      //  lighten(rgba(253, 0, 0, 0.7), 15)
      //}
    }
  }

  .my-configs-browser {
    //flex: 1 0 100%;
    //height: 200px;
    //border: 1px solid white;
    padding: 15px;
    display: flex;
    flex-flow: column nowrap;

    &--title {
      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
      color: #FFFFFF;
      margin-bottom: 15px;
    }

    &--list {
      width: 100%;
      //border: 1px solid black;
      //height: 400px;
      &--content {
        display: flex;
        flex-flow: column nowrap;

        .config-item {
          //width: 100%;
          //height: 200px;
          //border: 1px solid black;
          margin: 15px 3px;
          padding: 15px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-flow: row wrap;
          //background: linear-gradient(360deg, rgba(34, 34, 34, 0.84) 35.94%, rgba(34, 34, 34, 0) 100%);
          border-radius: 14px;
          //border: 1px solid white;
          //background: linear-gradient(360deg, rgba(34, 34, 34, 0.84) 99.99%, rgba(34, 34, 34, 0) 100%);
          //box-shadow: 0px 0px 7px 5px rgba(255, 255, 255, 0.3);
          position: relative;
          //background-image: url('~/userConfig.png');
          //background-image: url('~timber.png');
          //background-image: url("/Component_1.png");
          //background-image: url('/userConfig.png');
          color: #FFFFFF;
          .backImage {
            position: absolute;
            opacity: 0.2;
            z-index: 1;
            width: 100%;
            //padding: inherit;
            left: 0;
            top: 0;
            height: 100%;
            //display: none;
            user-select: none;
            -webkit-user-drag: none;
            filter: blur(4px);
          }

          &--index {
            color: white;
            font-size: 20px;
            line-height: 20px;
            /* identical to box height, or 100% */

            text-align: center;
            letter-spacing: 0.21em;
            text-transform: uppercase;
            background: #161616;
            //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.59);
            border-radius: 10px;
            //padding: 15px;

            @extend .centered-flex;
            width: 40px;
            height: 40px;
            user-select: none;
            z-index: 2;
          }

          &--title-with-text {
            margin-left: 31px;
            margin-right: 19px;
            z-index: 2;
            background: #161616;
            flex: 1;
            padding: 0 15px;
            .config-item--title {
              .input {
                width: 100%;
                input {
                  background-color: black;
                  color: white;
                }
              }
            }
          }

          &--title {
            font-family: Ubuntu;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 40px;
            /* identical to box height, or 107% */


            /* Gray / Light */

            color: #CDCDCD;
            cursor: pointer;
            z-index: 2;
            user-select: none;
          }

          &--author {
            font-weight: bold;
            font-size: 9px;
            line-height: 20px;
            /* identical to box height, or 222% */

            letter-spacing: 0.21em;
            text-transform: uppercase;


            z-index: 2;

          }
          .code-btn {
            background: #161616;
            flex: 1;
            font-family: Ubuntu;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 40px;
            padding: 0 15px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            cursor: pointer;
            user-select: none;
            svg {
              margin-right: 10px;
            }
          }
          .lock-btn {
            background: #161616;
            line-height: 40px;
            //display: flex;
            //justify-content: center;
            //align-items: center;
            height: 40px;
            margin: 0 12px;
            padding: 0 5px 0 8px;
            cursor: pointer;
            &:hover {
              svg {
                path {
                  fill: lighten(red, 15);
                }
              }
            }
          }

          .deleteIcon {
            //position: absolute;
            //top: 5px;
            //right: 5px;
            //width: 40px;
            background: #161616;
            line-height: 40px;
            display: flex;
            //justify-content: center;
            //align-items: center;
            height: 40px;
            margin: 0 12px;
            padding: 0 5px 0 8px;
            cursor: pointer;
            color: white;
            &:hover {
              color: red;
            }
          }

          &--actions {
            margin-left: auto;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            color: white;
            z-index: 2;

            .activeBtn {
              box-shadow: 0px 17px 33px rgba(255, 255, 255, 0.2);
            }



            &--code {
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              background: #161616;

              border: 2px solid #161616;
              border-radius: 33px;
              padding: 10px 20px;

              .code {
                margin: 0 5px;
              }
            }



            button {
              //background-color: white;
              //color: black;
              margin-left: 10px;
            }
          }
        }
      }


    }
  }
}
