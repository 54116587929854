
.left-navbar {
  background-color: #25262D;
  display: flex;
  flex-flow: column nowrap;
  //width: 20%;
  max-width: 300px;
  width: 300px;
  min-width: 300px;
  height: 100vh;
  color: white;
  font-size: $base-font-size;
  user-select: none;

  .header {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    align-items: center;
    font-size: 1.2em;

    .icon {
      width: 75px;
      height: 75px;
      flex: 0 1 auto;
    }

    .settings {
      flex: 0 1 auto;
      margin-right: 15px;
      cursor: pointer;
      color: #778192;;

      .dropdown-content {
        font-size: 20px;
        width: 150px;
        //margin-right: 50px;
        z-index: 50000;

      }

      .dropdown-item {
        padding: 5px 15px;
        height: fit-content;
        z-index: 1000000;
        //border-left: 1px solid #778192;
        //border-right: 1px solid #778192;
        &:first-child {
          //border-top: 1px solid #778192;
        }

        &:last-child {
          //border-bottom: 1px solid #778192;
        }

        //color: #778192;
        &-content {
          .icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .notificationIcon {
      width: calc(1.18em);
      height: calc(1em);
      margin-right: 5px;
    }

    .login {
      flex: 1 1 auto;
      font-family: RobotoCustom, serif;
      font-style: normal;
      font-weight: normal;
      font-size: .5em;
      line-height: 1;
      /* identical to box height */
      word-wrap: break-word;

      /* sidebar/primary */

      color: #778192;

      overflow: hidden;
    }
  }

  .divider {
    border-bottom: 1px solid rgba(119, 129, 146, 1);
  }

  .expander-container {
    margin-top: 15px;
    font-size: 0.6em;
  }

  .left-navbar__footer {
    flex: 0 1 50px;
    display: flex;
    flex-flow: row nowrap;
    //width: 100%;
    border-top: 1px solid rgba(119, 129, 146, 1);

    //.left-navbar__footer__toggle {
      //flex: 0 1 50px;
      //flex: 1 1 auto;
      height: 100%;
      display: flex;
      //flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      //outline: 1px solid red;
      margin-bottom: 5px;
      font-size: 0.7em;
      line-height: 0.7em;
      //height: 50px;

      color: rgba(119, 129, 146, 1);
      cursor: pointer;

      .icon {
        transform: scale(1.2);
        @extend .centered-flex;
        height: 80%;
        margin-right: 10px;

        svg {
          transition-duration: 0.4s;
          transition-property: transform;
        }
      }

      .text {
        height: 100%;
        @extend .centered-flex;
      }

      &:hover {
        color: lighten(rgba(119, 129, 146, 1), 25);

        svg {
          transform: rotate(360deg);
          -webkit-transform: rotate(360deg);

          path {
            //transform: rotate(90deg);
            fill: lighten(rgba(119, 129, 146, 1), 25);;
            //fill: red;
          }
        }
      }
    //}
  }

  &--hidden {
    max-width: 75px;
    width: 75px;
    min-width: 75px;

    .header {
      .login, .spacer, .settings {
        display: none;
      }
    }

    .expander-container {
      .header {
        display: none;
      }

      .content {
        .expanded-item {
          padding-left: 0;
          justify-content: center;

          .icon {
            margin-right: 0;
          }
        }

        .text {
          display: none;
        }
      }
    }

    .left-navbar__footer {
      .text {
        display: none;
      }

      .icon {
        svg {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
      }

      &:hover {
        svg {
          transform: rotate(-180deg);
          -webkit-transform: rotate(-180deg);
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .left-navbar {
    max-width: 75px;
    width: 75px;
    min-width: 75px;

    .header {
      .login, .spacer, .settings {
        display: none;
      }
    }

    .expander-container {
      .header {
        display: none;
      }

      .content {
        .expanded-item {
          padding-left: 0;
          justify-content: center;

          .icon {
            margin-right: 0;
          }
        }

        .text {
          display: none;
        }
      }
    }

    .left-navbar__footer {
      display: none;
      .text {
        display: none;
      }

      .icon {
        svg {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
      }

      &:hover {
        svg {
          transform: rotate(-180deg);
          -webkit-transform: rotate(-180deg);
        }
      }
    }
  }
}
