.payments-list {
  .payments-list__title {
    @extend .centered-flex;
    color: white;
    font-size: 70px;
    line-height: 86px;
    margin-bottom: 20px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
  }

  .payments-list__content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .provider {
      margin-right: 16px;
      margin-bottom: 16px;
      width: 112px;
      height: 90px;
      cursor: pointer;
      //background: -webkit-gradient(linear, right top, left top, from(#eb2e7d), to(#e82834));
      //background: linear-gradient(270deg, #eb2e7d, #e82834);
      //border-radius: 3px;
      //padding: 2px;
      //position: relative;
      background-color: white;
      outline: 1px solid black;
      &.provider--webp {
        background-color: unset;
        outline: unset;
      }
      .wrapper {
        @extend .centered-flex;
        height: 100%;
        width: 100%;
        img {
          max-width: 100%;
          max-height: 100%;
          margin: auto;
        }
      }
    }

    .icon-container {
      width: 125px;
      height: 125px;

      -webkit-transition: background .3s;
      transition: background .3s;
      background: rgba(0, 0, 0, .8);
      background: linear-gradient(
                      270deg, #eb2e7d, #e82834);
      border-radius: 3px;
      padding: 8px;
      @extend .centered-flex;

      img {
        //background-color: white;
        //border: 1px solid red;
        //width: 160px;
        height: 50px;
      }

      margin-left: 15px;
      margin-bottom: 15px;
    }

  }
}
