.maintenance {
  color: white;
  @extend .centered-flex;
  flex-flow: column;
  height: 100%;

  div {
    font-size: 50px;
    line-height: 60px;
  }
}
