.sub-info-block {
  //background: linear-gradient(180deg, #030303 0%, #242424 100%);/
  padding-bottom: 80px;
  margin-top: 200px;
  display: flex;
  flex-flow: column nowrap;

  &__header {
    text-transform: uppercase;
    display: flex;
    align-self: center;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 70px;
    line-height: 86px;
    color: white;
    @extend .centered-flex;
    margin-bottom: 65px;
  }

  &__extra-info {
    margin-top: 50px;
    @extend .centered-flex;
    color: white;
    font-size: 15px;
    .hightlighted {
      margin-left: .5em;
      color: #0b51ba;
    }
  }

  &__content {
    display: flex;
    flex-flow: row nowrap;
    color: white;
    justify-content: space-evenly;

    .subscribe_type_block {
      padding: 30px 30px;
      border: 3px solid white;
      border-radius: 25px;
      width: 22%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;


      &__header {
        @extend .centered-flex;
        font-weight: bold;
        font-size: 24px;
      }

      &__logo {
        width: 100%;
        //height: 370px;

        &.invoker {
          position: relative;
          .img {

            width: 100%;
            &.invo {
              position: absolute;
              top: 0;
              left: 0;
            }
            //height: 300px;
          }
        }
      }

      &__feature {
        //mart
        .subscribe_type_block__btn {
          @extend .centered-flex;
          margin-top: 30px;
        }
        .subscribe_type_block__price {
          @extend .centered-flex;
          margin-top: 10px;
          font-size: 22px;
          line-height: 25px;
        }
        .ui.button.dvn__btn {
          //background: #FFFFFF;
          //border: 1px solid #E8E8E8;
          //box-sizing: border-box;
          //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 134px;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .sub-info-block__header {

    }
    .sub-info-block__content {
      flex-flow: column nowrap;
      align-items: center;

      .subscribe_type_block {
        width: 60%;
        margin-bottom: 25px;
        &__feature {
          font-size: 25px;
          line-height: 30px;
          .ui.button.dvn__btn {
            font-size: 20px;
          }
        }
      }
    }
  }
}
