.circle_progress_loader {
  display: block;
  margin: 20px auto;
  max-width: 100%;
  position: relative;
}

.svg-circle-bg {
  fill: #192125;

}

.svg-circle {
  fill: none;

}

.svg-circle-text {
  font-size: 2rem;
  text-anchor: middle;
  fill: #fff;
  font-weight: bold;
}

.circle_progress_loader__text {
  font-size: 2rem;
  text-anchor: middle;
  fill: #fff;
  font-weight: bold;

  display: flex;
  @extend .centered-flex;
  flex-direction: column;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  .circle_progress_loader__text__count {
    margin-bottom: 10px;
    font-size: 1.5em;
    line-height: 1.7em;
  }
  .circle_progress_loader__text__title {
    font-size: 0.7em;
    line-height: .9em;
  }
}
