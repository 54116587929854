.after-login-content {
  display: flex;
  flex-flow: row nowrap;
  //height: 100%;
  //width: 100%;
  font-size: 36px;
  //background-color: rgb(240, 240, 240);
  line-height: 0.6em;

  .block {
    position: relative;
    background-color: #ffffff;
    display: flex;
    flex-flow: column nowrap;
    padding: 20px;
    margin: 5px;
    box-shadow: 0 0 4px rgba(#E8E8E8, 1);

    .header {
      display: flex;
      justify-content: space-between;
      font-size: .6em;
    }

    .sub-header {
      font-size: .5em;
      color: #9EA2A9;
    }

    .balance {
      height: 2em;
      display: flex;
      align-items: center;
    }

    .footer {
      margin-top: 20px;

      .btn {
        font-size: .7em;
        border-radius: 5px;
        height: 2em;

        horiz-align: center;
        text-align: center;
      }
    }

    &-system {
      .el {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
      }
    }

    &-download-and-start {
      user-select: none;

      .content {
        @extend .centered-flex;

        .buttons {
          display: flex;
          flex-flow: row nowrap;
          font-size: 20px;
          font-family: RobotoCustom, serif;
          font-style: normal;
          font-weight: bold;
          line-height: 32px;
          transition: .25s;

          .btn {
            flex: 0 1 auto;
            margin: 0;
            border-radius: 0;
            background-color: #2792e5;
            color: #fff;
            text-shadow: none;
            background-image: none;
            @extend .centered-flex;
            padding: 5px 25px;
            text-transform: uppercase;
          }

          .btn-one {
            @include btn-style(#2792e5);
          }

          .or {
            position: relative;
            width: .3em;
            height: 2.57142857em;
            z-index: 3;

            &::before {
              position: absolute;
              text-align: center;
              border-radius: 500rem;
              content: 'or';
              top: 50%;
              left: 50%;
              background-color: #fff;
              text-shadow: none;
              margin-top: -.89285714em;
              margin-left: -.89285714em;
              width: 1.78571429em;
              height: 1.78571429em;
              line-height: 1.78571429em;
              color: rgba(0, 0, 0, .4);
              font-style: normal;
              font-weight: 700;
              -webkit-box-shadow: 0 0 0 1px transparent inset;
              box-shadow: 0 0 0 1px transparent inset;
            }
          }

          .btn-two {
            @include btn-style(red);

            a {
              color: #fff;
            }

            //color: #fff;
          }
        }

        .start-btn {
          @include btn-style(#FC6E51);
        }

        .download-btn {
          @extend .centered-flex;
          font-family: RobotoCustom, serif;
          font-style: normal;
          font-weight: bold;
          font-size: 48px;
          line-height: 32px;
          transition: .25s;

          svg {
            border-bottom: 4px solid #5BC044;
          }

          &:hover {
            cursor: pointer;
          }

          .start {
            //border: 1px solid black;
            padding: 15px 25px;

            &:hover {
              background-color: #9EA2A9;
              color: white;
            }
          }

          //border-radius: 25px;
          //display: flex;
          //justify-content: center;
          //align-items: center;
          //padding: 0 25px;
        }

        .online-content {
          display: flex;
          flex-flow: column nowrap;
          //height: 300px;
          //width: 100%;
          //outline: 1px solid red;

          //outline: 1px solid red;
          .progress-bar {
            margin-top: 15px;
          }

          .start-btn {
            //outline: 1px solid red;
            margin: 0 250px;
          }

          .scroll-bar-container {
            width: 100%;
            //outline: 1px solid red;
            //height: 50px;
          }
        }
      }
    }

    &-balance {
      .header {
        color: #9EA2A9;
      }

      height: 210px;
    }

    &-buy_sub {
      //height: 210px;
      //max-height: 250px;

      .content {
        font-size: 0.8em;
        margin: 20px 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;

        button {
          margin-left: 15px;
        }

        .price-info {
          //border: 1px solid #E8E8E8;
          width: 200px;
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-around;
          padding: 5px;
          height: 46px;

          background: #FFFFFF;
          border: 1px solid #E8E8E8;
          box-sizing: border-box;
          border-radius: 2px;
          //box-shadow: 2px 2px 15px rgba(#E8E8E8, 2);

          .info {
            font-size: .6em;
            color: #ABABAB;
            line-height: .8em;
          }

          .prices {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
          }

          .price {
            font-size: 0.9em;
            line-height: 1.1em;
          }
        }

        .mark {
          color: #9EA2A9;
        }

        .rc-slider-handle {
          //border: 1px solid red;
          //width: 15px;
          //height: 15px;
        }

        .btn {
          //padding: 15px 35px;
        }
      }

      .ui.button.dvn__btn {
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 134px;
      }

      .block__header {
        justify-content: flex-start;

        &__action {
          margin-left: 5px;
        }
      }
    }

    &-system, &-vac {
      .content {
        margin-top: 25px;
        font-size: 1.3em;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        font-weight: 500;
        color: #2F3A4A;
      }
    }

    &-updates {
      flex: 1 1 auto;

      .header {
        font-weight: bold;
      }

      .block__content {
        display: flex;
        flex-flow: column nowrap;
        font-size: .7em;
        height: 100%;

        .item {
          display: flex;
          justify-content: space-between;
          padding: 25px 5px;
          border-bottom: 1px solid #EFF2F9;

          .name-with-icon {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            .icon {
              border-radius: 50%;
              width: 75px;
              height: 75px;

              .plugin-icon {
                width: 100%;
                height: 100%;
              }
            }

            .text {

            }
          }

          .date {

          }

          cursor: pointer;

          &:hover {
            background-color: darken(white, 5%);
          }
        }
      }
    }

    .link {
      color: red;
      cursor: pointer;
    }

    .block-disabled {
      background-color: rgba(black, .70);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 500;
      display: flex;
      @extend .centered-flex;
      color: white;
      user-select: none;
      cursor: not-allowed;
    }
  }

  .block {
    &-purchases {
      flex: 1 1 auto;
    }

    &-sub_left {
      flex: 0 1 250px;
    }
  }

  .col {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 70%;

    &:first-child {
      flex: 0 0 300px;
    }
  ;
  }

  .after-login-content__low_screen {
    display: none;
  }

  .block-purchases {
    flex: 1 1 auto;
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscribe_block {
    flex: 0 0 290px;
    @extend .centered-flex;
  }

  @media screen and (max-width: 1200px) {
    .col {
      display: none;
    }
    .after-login-content__low_screen {
      flex: 1 1 auto;
      display: flex;
      flex-flow: column nowrap;

      .block-purchases {
        min-height: 500px;
      }

      .block-updates {
        min-height: 500px;
      }
    }
  }
}

.block-buy_sub__popup {
  &--title {
    border-bottom: 1px solid black;
    font-size: 1.2em;
    font-weight: 700;
  }

  &--content {
    margin-top: 5px;
    font-size: 0.8em;
    display: flex;
    flex-flow: column nowrap;

    &-extra {
      border-top: 1px dotted black;
      font-size: 0.7em;
    }
  }
}

@function vh($quantity) {
  @return calc(var(--vh, 1vh) * #{$quantity});
}

.authed-place {
  display: flex;
  flex-flow: column nowrap;
  background-color: $primary_background;
  //height: 100vh;
  height: vh(100);
  //height: 100%;

  .navbar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;
    user-select: none;

    .logo {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      cursor: pointer;

      .logo--icon {
        width: 100px;
        height: 100px;
      }

      .logo--text {
        color: white;
        font-size: 35px;
        line-height: 50px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .link-group {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .cog.icon, .dropdown {
        color: white;
        margin-right: 15px;
        cursor: pointer;
      }

      .link {
        margin-right: 10px;
        border-radius: 25px;
        padding: 4px 14px;
        color: white;
        font-weight: 700;
        font-size: 17px;
        line-height: 30px;
        border: 2px solid transparent;
        transition: all 0.24s cubic-bezier(0.23, 1, 0.32, 1);

        &:hover {
          border: 2px solid white;
        }

        &.active {
          background-color: rgba(255, 142, 37, 1);
        }
      }

      .exit-btn {
        margin-right: 10px;
        cursor: pointer;

        path {
          transition: all 0.24s cubic-bezier(0.23, 1, 0.32, 1);
        }

        rect {
          transition: all 0.24s cubic-bezier(0.23, 1, 0.32, 1);
        }

        &:hover {
          svg {
            //stroke: red;
            path {
              fill: rgba(255, 142, 37, 1);
            }

            rect {
              stroke: rgba(255, 142, 37, 1);
            }
          }
        }
      }
    }
  }

  &--content {
    flex: 1 0 auto;
    //height: 500px;
    //width: 500px;
    //max-width: 1500px;
    //@extend .centered-flex;

    > .rc-scrollbars-container {
      > .rc-scrollbars-view {
        display: flex;
        flex-flow: column;
        align-items: center;

        .footer {
          width: 100%;
        }
      }
    }

    .after-login-content {
      //background-color: #0A0A0A;
      display: flex;
      flex-flow: column nowrap;
      max-width: 1600px;

      .main-contain-page-block {
        margin: 0 120px;
        padding: 45px 0;
        color: white;

        & + .main-contain-page-block {
          border-top: 2px solid white;
        }
      }

      .welcome-info {
        display: flex;
        flex-flow: column nowrap;

        &--title {
          display: flex;
          flex-flow: row nowrap;
          font-weight: bold;
          line-height: 40px;
          color: rgba(205, 205, 205, 1);

          .title--bold {
            color: rgba(255, 142, 37, 1);
            text-transform: uppercase;
            margin-left: 5px;
          }

          .title--status {
            margin-top: 14px;
            margin-left: 15px;
            border-radius: 50%;
            border: 1px solid rgba(255, 255, 255, 0.5);
            height: 16px;
            width: 16px;
            background-color: red;

            &--active {
              background-color: green;
            }
          }
        }

        &--description {
          font-size: 15px;
          line-height: 30px;
          color: rgba(205, 205, 205, 1);
        }
      }

      .user-info-block {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        .user-info-block__sub {
          flex: 0 0 35%;
          display: flex;
          flex-flow: row nowrap;
          font-size: 17px;
          line-height: 22px;
          margin-bottom: 26px;


          &:nth-child(2), &:nth-child(4) {
            //justify-content: flex-end;
          }

          .content {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-around;
            margin-left: 15px;

            &--value {
            }
          }
        }
      }

      .start-client-block {
        display: flex;
        flex-flow: column nowrap;
        //min-height: 245px;
        position: relative;

        &--icon {
          display: none;
          position: absolute;
          top: 0px;
          right: 150px;
          width: 268px;
          height: 372px;
        }

        &--info {
          font-size: 15px;
          line-height: 20px;
          font-weight: bold;
        }

        &--title {
          font-size: 36px;
          line-height: 40px;
          font-weight: bold;
        }

        &--content {
          @extend .centered-flex;

          .start-dota-block {
            width: 700px;

            &--buttons {
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-around;

              button {
                background-color: $primary_background;
                display: flex;
                align-items: center;
                color: white;
                letter-spacing: 0.21em;
                text-transform: uppercase;

                svg {
                  margin-right: 5px;
                }

                &:hover {
                  background-color: white;
                  color: black;
                }
              }

              .download-btn {
                border: 2px solid #0070af;

                &--downloading {
                  background-color: transparent !important;
                  border: 2px solid dimgray;
                  color: dimgray !important;
                  cursor: unset !important;
                  svg {
                    path {
                      fill: dimgray;
                    }
                  }
                }
              }

              .start-client-btn {
                border: 2px solid #FF0000;
                //display: flex;
                //flex-flow: row nowrap;
                //justify-content: center;
                padding: 0;

                a {
                  padding: 14px 31px;
                }

                svg {
                  //margin-right: 3px;
                }

                &:hover {
                  a {
                    color: black;
                  }
                }
              }
            }

            &--progress {
              .progress-bar {
                @extend .centered-flex;
                flex-flow: column nowrap;

                .bar {
                  width: 80%;
                  margin-bottom: 5px;
                  margin-top: 15px;
                }
              }
            }

            &--button {
              @extend .centered-flex;

              button {
                //background-color: #0A0A0A;
                display: flex;
                align-items: center;
                color: white;
                letter-spacing: 0.21em;
                text-transform: uppercase;
              }

              .start-btn {
                border: 1px solid white;
                padding: 20px 35px;

                .play.icon {
                  margin-right: 10px !important;
                }
              }
            }
          }
        }
      }

      .other-settings-block {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        .other-settings-block__sub {
          flex: 0 0 35%;
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
          font-size: 17px;
          line-height: 22px;
          margin-bottom: 26px;

          &--title {
            font-size: 35px;
            line-height: 40px;
            font-weight: bold;
            color: rgba(255, 142, 37, 1);
          }

          &--description {
            margin-bottom: 10px;
          }

          &:nth-child(3), &:nth-child(4) {
            .other-settings-block__sub--title {
              color: rgba(0, 112, 175, 1);
            }
          }

          .content {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-around;
            margin-left: 15px;
          }
        }
      }
    }
  }
}


.fade-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(100%);
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms, transform 500ms;
}


@media screen and (max-width: 900px) {
  .rc-scrollbars-view {
    width: 100vw;
    @media only screen and (hover: none) {
      width: unset;
    }

  }
  .authed-place {
    .navbar {
      flex-flow: column nowrap;
      align-items: flex-start;
      margin-bottom: 25px;
    }

    &--content {
      .after-login-content {
        width: 100%;

        .main-contain-page-block {
          //max-width: 500px;
          margin: 0 20px;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          //width: 55%;
          .welcome-info--description {
            //width: 55%;
          }
        }

        .start-client-block--content {
          width: 100%;

          .start-dota-block {
            .animated-container {
              .content {
                .start-dota-block--buttons {
                  flex-flow: column nowrap;
                  align-items: center;

                  button {
                    width: fit-content;
                    height: 52px;
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }

        }

        .user-info-block, .other-settings-block {
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-start;

          &__sub {
            //width: 55%;
          }

          .other-settings-block__sub--description {
            //overflow-wrap: anywhere;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .authed-place {
    &--content {
      .after-login-content {
        width: 100%;
      }
    }
  }
}

.admin_navbar__container {
  padding: 5px;
  outline: 1px solid white;
  margin-right: 1px;
  background-color: #1A1A1A;

  .admin_navbar__item {
    color: darken(white, 20);
    padding: 5px 10px;
    //outline: 1px solid darken(white, 15);
    cursor: pointer;
    @extend .centered-flex;
    justify-content: flex-start;
    transition: all .25s cubic-bezier(0.23, 1, 0.32, 1);

    &:hover {
      outline: 1px solid white;
      color: white;
    }
  }
}
