body {
  //background-color: rgba(155, 0, 0, .5);
  //background-image: url('/background.jpg');
}


$base-font-size: 46px;
$whiteColor: #FFFFFF;
$redColor: rgba(#FD0000, .8);
$blueColor: rgba(#0066FF, .8);
$authBackgroundColor: #0C0C0C;
$max-width-content: 1900px;

.bottom-border {
  cursor: pointer;

  &:after {
    margin-top: 3px;
    display: block;
    content: '';
    border-bottom: 3px solid red;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  &:hover:after {
    transform: scaleX(1);
  }

  &.fromRight:after {
    transform-origin: 100% 50%;
  }

  &.fromLeft:after {
    transform-origin: 0 50%;
  }
}

@function vh($quantity) {
  @return calc(var(--vh, 1vh) * #{$quantity});
}

.welcome-page--base {
  width: 100%;
  overflow-x: hidden;
  height: vh(100);

  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }

  .welcome-page--content {
    display: flex;
    flex-flow: column nowrap;
    background: #000000;

    .welcome-page__top {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      background: #101010;
      flex: 0 0 70px;

      .welcome-page__top__part {
        display: flex;
        flex-flow: row nowrap;

        &:first-child {
          flex: 0 0 295px;
        }

        &:last-child {
          //flex: 0 1 295px;
        }


        .top__item {
          //color: #FFFFFF;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          margin: 0 18px;

          user-select: none;
          position: relative;

          font-family: Inter, serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          /* identical to box height */


          color: #EEEEEE;

          &--link {
            cursor: pointer;

            &:before {
              transition: all .15s ease;
              position: absolute;
              content: '';
              width: 10%;
              left: 50%;
              bottom: 20px;
              height: 1px;
              background: transparent;
            }

            &:hover {
              &:before {
                background: white;
                width: 100%;
                left: 0;
              }
            }
          }

          &.logo {
            img {
              user-select: none;
              width: 75px;
              //height: 88px;
            }
          }

          &.title {
            font-family: Oswaldlight, serif;
            font-style: normal;
            font-weight: 200;
            font-size: 40px;
            //font-size: clamp(15px, 40px, 4vw);
            line-height: 59px;
            letter-spacing: 0.02em;
          }

          .top__item__icon {
            margin-right: 7px;
          }

          &--hoverable {
            background: linear-gradient(90.61deg, #FE1F27 -67.16%, rgba(254, 31, 39, 0) 90.31%);
            transform: rotate(-0.08deg);
            min-width: 80px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            margin-right: 0;
            transition: all .15s ease;

            svg {
              &:first-child {
                margin-bottom: 5px;
              }
            }

            .top__item--action {
              display: none;
              //margin: 0 clamp(5px, 1rem, 10px);
            }

            &:hover {
              min-width: 160px;
              flex-flow: row wrap;
              justify-content: space-around;

              svg {
                display: none;
              }

              .top__item--action {
                position: relative;
                display: block;


                &:before {
                  bottom: 0;
                }

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }

      }
    }

    .welcome-page__welcome-text {
      display: flex;
      flex-flow: column nowrap;
      padding: 20px 96px;
      align-items: center;
      .welcome-page__welcome-text--wrapper {
        max-width: $max-width-content;
        width: 100%;
      }

      .welcome-text__title {
        //font-family: Poppins;
        font-family: RobotoCustom, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 60px;
        /* identical to box height */

        letter-spacing: 0.02em;

        color: #FE1F27;
        margin-bottom: 16px;
      }

      .welcome-text__description {
        //font-family: Poppins;
        font-family: RobotoCustom, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        letter-spacing: 0.02em;

        color: #FFFFFF;
      }
    }

    .welcome-page__carousel {
      padding: 20px 96px;


      display: flex;
      justify-content: center;
      align-items: center;
      //user-select: none;

      .welcome-page__carousel--wrapper {
        max-width: $max-width-content;
        width: 100%;
        position: relative;
      }
      .carousel-image__wrapper {
        position: absolute;
        //top: -15%;
        top: 0%;
        right: 5%;
        //transform: translate(-50%, 0);
        //transform: translate(-50%, 0);
        //height: 125%;
        height: 100%;
        transition: all 0.5s ease-in;



        &--0 {
          top: -5%;
          height: 105%;
        }
        &--1 {
          top: -0%;
          //height: 105%;
          width: 752px;
          height: 587px;
        }
        &--2 {
          top: -25%;
          right: -5%;
          //height: 105%;
          width: 1118px;
          height: 740px;
          //transform: translate(-25%, 0);
        }
        &--3 {
          top: -30%;
          //height: 105%;
          width: 1118px;
          height: 719px;
          //transform: translate(-25%, 0);
        }
        &--4 {
          top: -22%;
          right: 3%;
          //height: 105%;
          width: 1021px;
          height: 622px;
          //transform: translate(-25%, 0);
        }
        .carousel-image {
          height: 100%;
        }
      }

      .carousel.carousel-slider {
        //overflow: unset;
        .slider-wrapper.axis-horizontal {
          //overflow: unset;
        }

      }

      .carousel__item {
        padding: clamp(0.5rem, 5vw, 2rem) 40px;
        //padding: 24px 40px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        color: white;
        position: relative;
        background: #151515;
        border-radius: 24px;
        //height: 482px;
        //height: 200px;
        height: clamp(200px, 50vw, 485px);
        font-size: clamp(1.5rem, 5vw, 3rem);
        line-height: clamp(1.5rem, 5vw, 4rem);
        overflow: hidden;

        &--1 {
          .carousel__item__actions {
            .button {
              &:first-child {
                background-color: #E00109;
                color: white;
              }
              &:last-child {
                color: #E00109;
                background-color: white;
              }
            }
          }
        }

        &--2 {
          .carousel__item__actions {
            .button {
              &:first-child {
                background-color: #5865F2;
                color: white;
              }
              &:last-child {
                color: #5865F2;
                background-color: white;
              }

            }
          }
        }

        &--3 {
          .carousel__item__actions {
            .button {
              &:first-child {
                background-color: #05FF00;
                color: white;
              }
              &:last-child {
                color: #05FF00;
                background-color: white;
              }

            }
          }
        }

        &--4 {
          .carousel__item__actions {
            .button {
              &:first-child {
                background-color: #0077FF;
                color: white;
              }
              &:last-child {
                color: #0077FF;
                background-color: white;
              }
            }
          }
        }

        &--5 {
          .carousel__item__actions {
            .button {
              &:first-child {
                background-color: #1FBBFE;
                color: white;
              }
              &:last-child {
                color: #1FBBFE;
                background-color: white;
              }

            }
          }
        }

        .carousel__item__background {
          color: #955858;
          font-weight: 600;
          font-size: 300px;
          //line-height: 130%;
          position: absolute;
          left: 0;
          bottom: 50%;
          z-index: 1;
          width: 100%;
          //background: #000000;
          &.carousel__item__background-image {
            bottom: 0;
            height: 100%;
            &--1 {

            }
            img {
              height: 100%;
            }
          }

          &.sf {
            img {

              //width: 30%;
              width: calc(965px / 100 * 80);
              height: calc(745px / 100 * 80);

            }

            bottom: 0;
          }
        }

        .carousel__item__content {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          z-index: 2;
          height: 100%;
          //min-width: 350px;
          //max-width: 525px;
          //max-width: Min(25%, 525px);
          //max-width:  clamp(400px, 25%, 525);
          //max-width: Min(50vw, 525px);
          width: clamp(300px, 40vw, 525px);
          //fontsi
          //width: clamp(400px, 25%, 525);
        }

        .carousel__item__title {
          //font-family: Poppins;
          font-family: RobotoCustom, serif;
          font-style: normal;
          font-weight: normal;
          font-size: .35em;
          line-height: clamp(1.0rem, 3vw, 3rem);
          /* identical to box height */

          letter-spacing: 0.02em;

          color: #FBFBFB;
        }

        .carousel__item__text {
          font-family: Oswaldlight, serif;
          font-style: normal;
          font-weight: 600;
          //font-size: 50px;
          //line-height: 74px;
          letter-spacing: 0.02em;
          //min-width: 422px;
          //max-width: 750px;
          text-align: left;

          &--highlighted {
            color: red;
            margin: 0 8px;
          }

          &--blue {
            color: #5865F2;
          }

          &--water-blue {
            color: #1FBBFE;
          }
          &--super-blue {
            color: #0077FF;
          }

          &--green {
            color: #05ff00;
          }
        }

        .carousel__item__actions {
          margin-top: auto;
          display: flex;
          flex-flow: row nowrap;
        }
      }
    }

    .welcome-page__just-description {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      padding: 20px 0 20px 96px;


      .just-description__title {
        flex: 0 0 60%;

        font-family: Oswaldlight, serif;
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 53px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        z-index: 10;
        max-width: 800px;
      }

      .just-description__image {
        flex: 0 0 40%;
        max-width: 800px;
        img {
          //margin-left: -250px;
          //width: calc(100% + 250px);
          width: 100%;
          //width: clamp(350px, 75%, 800px);
        }
      }
    }

    .trial-games-banner--wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 100px 0;
    }

    .trial-games-banner {
      max-width: $max-width-content;
      width: 100%;
      //align-self: center;
      position: relative;
      .trial-games-banner__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        //height: 350px;
        height: 100%;
      }
      height: 500.55px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      .trial-games-banner__actions--wrapper {
        z-index: 10;
        position: absolute;
        top: 0;
        right: 300px;
        .trial-games-banner__actions {
          width: fit-content;
          margin-top: 50px;
          &--eng {
            margin-top: 0;
          }
        }
        //margin-right: 500px;;
        flex: 1 0 auto;
        color: white;
        font-family: RobotoCustom, serif;;

        .actions__title {
          text-wrap: none;
          overflow-wrap: normal;
          font-style: normal;
          font-weight: normal;
          font-size: 56px;
          line-height: 60px;

        }
        .actions__description {
          font-family: RobotoCustom, serif;;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 15px;
          text-align: center;
        }

        .actions__button {
          margin-top: 75px;
          background: linear-gradient(180deg, #6BE1FF 0%, #0098C2 118.27%);
          border-radius: 18px;
          color: white;
          //height: 63px;
          font-weight: 600;
          font-size: 24px;
          line-height: 16px;
        }
      }

      .trial-games-banner__hero {
        flex: 0 1 500px;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }

      .trial-games-banner__images {
        flex: 0 0 500px;
        //border: 1px solid red;
        //position: relative;
        position: absolute;
        width: 500px;
        height: 100%;
        top: 0;
        right: 0;
        .banner__images__vector {
          position: absolute;
          top: 0;
          left: 10%;
          width: 234px;
          height: 202px;
        }
        .banner__images__tree1 {
          position: absolute;
          bottom: 0;
          left: 20%;
        }
        .banner__images__tree2 {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }

    .halloween {
      height: 545px;
      //background: #6132C9;
      //background: #de2f32;
      display: flex;
      flex-flow: row nowrap;
      position: relative;

      img {
        //width: 35%;
        width: 720px;
        height: 545px;
      }

      .halloween__description {
        //font-family: Poppins;
        font-family: RobotoCustom, serif;
        font-style: normal;
        font-weight: normal;
        //font-size: 48px;
        //line-height: 72px;
        font-size: clamp(0.5rem, 4vw, 3rem);
        line-height: calc(clamp(0.5rem, 4vw, 3rem) * 1.2);

        color: #FFFFFF;
        width: 100%;
        max-width: 1200px;

        display: flex;
        flex-flow: column nowrap;
        margin-left: auto;
        //align-items: center;
        padding: 70px 30px;
        width: fit-content;

        span {
          &:first-child {
            margin-bottom: auto;
          }
        }

        .halloween__description__button {
          background: #FCA120;
          border-radius: 8px;
          margin: auto auto 0 auto;
          //margin-left: auto;
          //margin-right: auto;

          color: #FFFFFF;
          width: fit-content;

          &:hover {
            background: lighten(#FCA120, 5%);
          }
        }
      }
    }

    .welcome-page__dashboard {
      padding: 20px 0;
      //filter: blur(10px);
      height: 546px;
      position: relative;

      img {
        width: 100%;

        &.dashboard--front {
          position: absolute;
          top: 0;
          left: 20%;
          right: 20%;
          width: 60%;
          height: 100%;

        }
      }
    }

    .welcome-page__youtube {
      padding: 50px 0;
      position: relative;

      //.player-wrapper {
      //  width: auto; // Reset width
      //  height: auto; // Reset height
      //}
      //.react-player {
      //  padding-top: 56.25%; // Percentage ratio for 16:9
      //  position: relative; // Set to relative
      //}
      //
      //.react-player > div {
      //  position: absolute; // Scaling will occur since parent is relative now
      //}

      .player-wrapper {
        position: relative;
        padding-top: 56.25%; /* 720 / 1280 = 0.5625 */

      }

      .react-player {
        position: absolute;
        top: 0;
        left: 0;
        filter: blur(7px);
      }

      .welcome-page__youtube__title {
        font-family: Oswaldlight, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 47px;
        //text-align: center;
        letter-spacing: 0.02em;

        color: #FFFFFF;

        text-align: left;

        padding: 25px 90px;
      }

      .welcome-page__youtube__player {
        position: relative;
        user-select: none;
        width: 100%;
        height: 100%;
        //border: 5px solid red;
        min-height: 550px;
      }

      .welcome-page__youtube__overlay {
        color: #FFFFFF;
        position: absolute;
        top: 0;
        left: 0;
        //top: 50%;
        //left: 50%;
        //transform: translate(-50%, -50%);
        z-index: 500;
        //background-color: rgba(0, 0, 0, 0.4);
        //filter: blur(4px);
        width: 100%;
        height: 100%;

        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        align-items: center;

        .welcome-page__youtube__overlay__logo {

          //font-family: Poppins;
          font-family: RobotoCustom, serif;
          font-style: normal;
          font-weight: bold;
          font-size: 40px;
          line-height: 60px;
          /* identical to box height */

          letter-spacing: 0.02em;

          color: #FFFFFF;

          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          //border: 1px solid white;

          .overlay__logo__item {
            //border: 1px solid white;
            display: flex;
            justify-content: center;
            align-items: center;

            &.logo {
              img {
                user-select: none;
                width: 75px;
                margin-top: 5px;
              }
            }
          }
        }

        .welcome-page__youtube__overlay__text {
          //font-family: Poppins;
          font-family: RobotoCustom, serif;
          font-style: normal;
          font-weight: bold;
          font-size: 72px;
          line-height: 108px;
          text-align: center;
          letter-spacing: 0.02em;

          color: #FFFFFF;

          width: clamp(500px, 800px, 50%);

        }

        .welcome-page__youtube__overlay__link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          border: 2px solid white;
          border-radius: 39px;
          padding: 5px 19px 5px 5px;

          .overlay__link__icon {
            position: relative;
            margin-right: 1rem;
            height: 65px;

            &--in {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(calc(-50% + 1px), -50%);
              //border: 1px solid red;
            }
          }

          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 29px;
          /* identical to box height */


          color: #EEEEEE;
          transition: all 0.35s;
          cursor: pointer;

          &:hover {
            background-color: darken(white, 15);
            color: black;

            svg {
              //fill: #0b51ba;
            }
          }
        }
      }
    }

    .welcome-page__buy {
      display: flex;
      flex-flow: column nowrap;
      padding: 40px 96px;
      align-items: center;

      .welcome-page__buy--wrapper {
        max-width: $max-width-content;
        width: 100%;
      }


      .welcome-page__buy__title {
        font-family: Oswaldlight, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 47px;
        text-align: left;
        letter-spacing: 0.02em;

        color: #FFFFFF;
      }

      .welcome-page__buy__list {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-top: 42px;

        .buy__list__item {
          background: #202020;
          box-shadow: 1px 13px 73px rgba(0, 0, 0, 0.15);
          border-radius: 24px;
          //width: 378px;
          //width: 25rem;
          //width: clamp(250px, 25rem ,378px);
          flex: 0 1 clamp(250px, 25rem ,378px);
          height: 508px;
          padding: 20px 24px;
          margin: 25px;


          display: flex;
          flex-flow: column nowrap;

          .buy__list__item__logo {
            width: 100%;
          }

          .buy__list__item__pre-title {
            font-family: Oswaldlight, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: white;
            margin-top: 16px;

            span {
              &:first-child {
                color: #FE1F27;
              }
            }
          }

          .buy__list__item__name {
            font-family: Oswaldlight, serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            /* identical to box height */

            //text-align: center;
            letter-spacing: 0.02em;

            color: #FFFFFF;
            margin-top: 16px;
          }

          .buy__list__item__info {
            display: flex;
            flex-flow: row nowrap;
            margin-top: 24px;

            .item__info__item {
              display: flex;
              flex-flow: column nowrap;
              margin-right: 80px;

              .item__info__title {
                font-family: Oswaldlight, serif;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                //text-align: center;
                letter-spacing: 0.04em;

                color: #FE1F27;
              }

              .item__info__value {
                font-family: Oswaldlight, serif;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 19px;
                //text-align: center;
                letter-spacing: 0.02em;

                color: #FFFFFF;
                margin-top: 8px;
              }
            }
          }

          .buy__list__item__action {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            background: #DB0C26;
            border-radius: 25px;
            padding: 8px 5px 8px 45px;
            margin-top: auto;

            .buy__list__item__action__name {
              margin-left: 1rem;
              font-family: Gotham Pro;
              font-style: normal;
              font-weight: bold;
              font-size: 11px;
              line-height: 20px;

              display: flex;
              align-items: center;

              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
}


.btn-group {
  display: flex;
  flex-flow: row nowrap;

  .btn {
    user-select: none;

    &:first-child {
      margin-right: 2px;
    }
  }

}

.block-buy_sub {
  .daysContainer {
    position: relative;

    .daysContainer__bonus {
      position: absolute;
      top: -1em;
      right: -1em;
      color: #6de76d;
    }
  }
}

.col {
  position: relative;
}

.payment-block {
  //position: absolute;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);
  //z-index: 500;
  //background-color: #00000099;
  padding: 150px;
}

.btn {
  padding: 3px 10px;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  @extend .centered-flex;
  transition: all 0.35s;

  &-red {
    @include btn-style($redColor)
  }

  &-blue {
    @include btn-style($blueColor)

  }

  &-white {
    @include btn-style(white);
    border: 1px solid #E8E8E8;
    color: black;
  }

  &-circle {
    width: 54px;
    height: 54px;

    svg {
      position: absolute;
    }
  }
}

.centered-flex {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.spacer {
  margin-left: auto;
}

@media screen and (max-width: 1100px) {
  //.top__item__icon {
  //  display: none;
  //}
  .welcome-page--base .welcome-page--content .trial-games-banner--wrapper {
    height: fit-content;

    .trial-games-banner {
      justify-content: center;

      .trial-games-banner__actions--wrapper {
        flex: unset;
      }
    }

    .trial-games-banner__images {
      .banner__images__vector {
        //display: none;
      }

    }
  }
  .welcome-page__top {
    .welcome-page__top__part {
      .top__item {
        &.top__item--hoverable {
          &:hover {
            &:hover {
              background: linear-gradient(90.61deg, #FE1F27 -67.16%, rgba(254, 31, 39, 0) 90.31%);
              transform: rotate(-0.08deg);
              min-width: 80px;
              display: flex;
              flex-flow: column nowrap;
              justify-content: center;
              align-items: center;
              margin-right: 0;
              transition: all .15s ease;

              svg {
                display: block;

                &:first-child {
                  margin-bottom: 5px;
                }

                cursor: pointer;
              }

              .top__item--action {
                display: none;
                //margin: 0 clamp(5px, 1rem, 10px);
              }
            }
          }
        }

        &.top__item--link {
          .top__item__icon__text {
            display: none;
          }
        }
      }
    }

  }
  .welcome-page__carousel {

    .carousel-image__wrapper {
      display: none;
    }

    .carousel__item {
      .carousel__item__content {
        width: 100% !important;
      }
    }
  }



  .welcome-page--base .welcome-page--content .welcome-page__just-description {
    .just-description__title {
      flex: 0 0 100%;
    }
    .just-description__image {
      display: none;
    }

  }

  .welcome-page--base .welcome-page--content .welcome-page__buy .welcome-page__buy__list {
    justify-content: center;
  }
}

@media screen and (max-width: 1400px) {
  .functions {
    .text-two {
      top: 0;
      left: 0;
    }
  }
}

@media screen and (max-width: 1500px) {
  .welcome-page--base .welcome-page--content .trial-games-banner--wrapper {
    height: fit-content;
    .trial-games-banner {
      justify-content: center;
      .trial-games-banner__actions--wrapper {
        flex: unset;

      }
    }
    .trial-games-banner__hero {
      //display: none;
      opacity: .2;
    }
    .trial-games-banner__images {
      //position: relative;
      .banner__images__vector {
        //left: 21%;
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .welcome-page--base .welcome-page--content .trial-games-banner--wrapper {
    .trial-games-banner {
      .trial-games-banner__actions--wrapper {
        position: unset;
        top: unset;
        right: unset;
      }
    }
    .trial-games-banner__hero {
      width: 100%;
      height: unset;
    }
    .trial-games-banner__images {
      .banner__images__vector {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .welcome-page__youtube > div.welcome-page__youtube__player.player-wrapper > div.welcome-page__youtube__overlay > div.welcome-page__youtube__overlay__text {
    width: 100%;
  }
  .welcome-page--base .welcome-page--content .halloween {
    //height: fit-content;
    .halloween__description {
      font-size: 48px;
      line-height: 72px;
      span {
        text-align: center;
      }
    }
    img {
      display: none;
    }
  }
  .welcome-page {
    .all-shit {
      height: unset;
      width: 90%;
    }

    .welcome-page-content {
      .title-with-text {
        width: 90%;
      }
    }

  }
  .functions {
    .functions-content {
      width: 100%;
      height: 100vh;
    }

    .forge {
      //width: 100%;
      //height: unset;
      .icon {
        //width: 100%;
        //height: unset;
      }
    }

    .text-two {
      top: 0;
      left: 0;
    }

    .text-one {
      right: 0;
      //top: 50px;
      bottom: 0;
      left: unset;
    }
  }
}
.ui.modal.transition.visible.active {
  background: $secondary_background;
  border-radius: 24px;
}
.trial-games__modal {
  position: relative;
  background: #1A1A1A;
  border-radius: 24px;
  padding: 37px 52px;
  .modal__close-btn {
    background: #0077FF;
    border-radius: 169px;
    top: 18px;
    right: 18px;
    width: 34px;
    height: 34px;
    position: absolute;
    //font-family: Work Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    user-select: none;
    transition: 0.25s all;
    .modal__close-btn__content {
      //margin: auto auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% + 0px), -50%);
    }
    &:hover {
      background: darken(#0077FF, 15%);
    }
  }
  color: #FFFFFF;
  display: flex;
  flex-flow: column nowrap;
  .modal__title {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 38px;
    align-self: center;
  }

  .modal__sub-title {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    align-self: center;
  }

  .modal__content {
    margin: 55px 0;
    display: flex;
    flex-flow: column nowrap;

    font-family: Work Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;

    span {
      &.vk {
        color: #0077ff;
      }
      &.discord {
        color: #0a7abf;
      }
    }
  }

  .modal__actions {
    align-self: center;
    background: #0077FF;
    border-radius: 169px;
    color: white;
  }
}